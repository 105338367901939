import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { isMobile } from "react-device-detect"
import Img from "gatsby-image"

import { family } from "../elements/font"
import colors from "../elements/color"
import GreenImage from "./GreenImage"

const ArticlesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 2vh;
  position: relative;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1366px) {
    align-items: flex-start;
  }
`

const ArticleLink = styled.a`
  width: ${props => (props.stretch ? "15%" : "fit-content")};
  margin: 0 10px;
  ${props => props.margin && "margin: 0 7px;"}
  text-align: center;
  font-family: ${family.Alata};
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: ${props => (props.green ? "#4A4A4A" : "black")};
  position: relative;
  z-index: 20;
  &:hover {
    ${props => !props.green && `color: ${colors.darkBlue};`}
  }
  @media only screen and (max-width: 800px) {
    width: 65%;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 40px;
    &:after {
      display: none;
    }
    .medium {
      display: none;
    }
    &.single {
      width: 95%;
      color: ${colors.darkBlue};
    }
  }
`

const ArticleNoLink = styled.div`
  width: fit-content;
  margin: 0 10px;
  text-align: center;
  font-family: ${family.Alata};
  font-size: 0.8rem;
  line-height: 1.2rem;
  letter-spacing: 0.05em;
  color: ${props => (props.green ? "#4A4A4A" : "black")};
  position: relative;
  z-index: 20;
  &:hover {
    ${props => !props.green && `color: ${colors.darkBlue};`}
  }
  @media only screen and (min-width: 1440px) {
    width: ${props => (props.stretch ? "15%" : "fit-content")};
  }
  @media only screen and (max-width: 800px) {
    width: 65%;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 40px;
    &:after {
      display: none;
    }
    .medium {
      display: none;
    }
    &.single {
      width: 95%;
      color: ${colors.darkBlue};
    }
  }
`

const ImageArticle = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: -1;
  bottom: -10px;
  ${props => (props.right ? "right: -45px;" : "left: 35px;")}
  transform: translate(-20%, 80%);
  opacity: 0;
  transition: opacity 0.3s ease;
`

const BackgroundCircle = styled.div`
  position: absolute;
  left: -50px;
  top: -20px;
  width: 140px;
  height: 140px;
  z-index: 5;
  border-radius: 50%;
  background: ${props => props.color};
  .medium {
    opacity: 1;
    width: 60px;
    height: 60px;
    bottom: 110px;
    left: 53px;
    &::after {
      content: "lire nos articles";
      position: absolute;
      bottom: -40px;
      left: 12px;
      font-size: 10px;
      line-height: 14px;
      font-family: ${family.Alfa};
    }
  }
  &.single {
    left: 140px;
    top: 40px;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    display: none;
    &.single {
      top: 80px;
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1366px) {
    top: 110px;

    &.small-top {
      top: 60px;
    }
  }
`

const GreenText = styled.p`
  background: rgb(119, 173, 123);
  background: linear-gradient(
    -90deg,
    rgba(119, 173, 123, 1) 35%,
    rgba(208, 213, 162, 1) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3vw;
  line-height: 3vw;
  margin-bottom: 20px;
  @media only screen and (min-width: 1000px) and (max-width: 1100px) {
    position: absolute;
  }
`

const ImageGreenContainer = styled.span`
    width: ${props => (props.index === 4 ? "20px" : "40px")};
    ${props => props.index !== 0 && "margin-top: 5px;"}
    position: absolute;
    ${props => props.index === 4 && "right: 35%;"}
    ${props => props.index === 3 && "right: 20%;"}
    ${props => props.index === 0 && "right: 30%;"}
    @media only screen and (min-width: 1440px) {
        ${props => props.index === 3 && "right: 35%;"}
    }
    @media only screen and (min-width: 450px) and (max-width: 590px) {
        ${props => props.index === 4 && "right: 15%;"}
        ${props => props.index === 3 && "right: 10%;"}
        ${props => props.index === 0 && "right: 10%;"}
    }
`

const Transparent = styled.span`
  opacity: 0;
`

const MediumArticles = ({
  circleColor,
  articles,
  stretch = false,
  green = false,
  greenImages = false,
  right = false,
  className = "",
  single = false,
  margin = false,
  smallTop = false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      medium: file(relativePath: { eq: "logo_medium.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticlesContainer>
      {isMobile && (
        <BackgroundCircle
          className={`${className} ${smallTop ? "small-top" : ""}`}
          color={circleColor}
        >
          <ImageArticle className="medium">
            <Img fluid={data.medium.childImageSharp.fluid} />
          </ImageArticle>
        </BackgroundCircle>
      )}
      {articles.map((article, index) =>
        article.link ? (
          <ArticleLink
            className={`${className} ${green ? "bubble" : "article"}`}
            right={right}
            data-circle={green ? article.hoverText : ""}
            stretch={stretch}
            margin={margin}
            key={index}
            href={article.link}
            target="_blank"
            data-color={single ? circleColor : colors[`circle${index + 1}`]}
            green={green}
          >
            {green && <GreenText>{article.greenData}</GreenText>}
            {article.title}
            {greenImages && (
              <ImageGreenContainer index={index}>
                <GreenImage index={index} />
              </ImageGreenContainer>
            )}
            <ImageArticle right={right} className={green ? "" : "medium"}>
              <Img fluid={data.medium.childImageSharp.fluid} />
            </ImageArticle>
          </ArticleLink>
        ) : (
          <ArticleNoLink
            className={`${className} ${green ? "bubble" : "article"}`}
            right={right}
            data-circle={green ? article.hoverText : ""}
            stretch={stretch}
            key={index}
            data-color={single ? circleColor : colors[`circle${index + 1}`]}
            green={green}
          >
            {green && <GreenText>{article.greenData}</GreenText>}
            {greenImages && article.title.match(/-\w+-/gm) ? (
              <>
                {article.title.split(/-\w+-/gm).map((item, i) => (
                  <span key={i}>
                    {item}
                    {i === 0 && <Transparent>-ecosia-</Transparent>}
                  </span>
                ))}
              </>
            ) : (
              article.title
            )}
            {greenImages && (
              <ImageGreenContainer index={index}>
                <GreenImage index={index} />
              </ImageGreenContainer>
            )}
            <ImageArticle right={right} className={green ? "" : "medium"}>
              <Img fluid={data.medium.childImageSharp.fluid} />
            </ImageArticle>
          </ArticleNoLink>
        )
      )}
    </ArticlesContainer>
  )
}

export default MediumArticles
