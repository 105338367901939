import React, { useContext } from "react"
import styled from "styled-components"
import { size, family } from "../../elements/font"
import { NumberStringHome, NumberMobile, TitleHome } from "../../elements/Text"
import MediumArticles from "../../components/MediumArticles"
import Svgmap from "./svgmap"
import { NumbersContainer, Explanation, Number } from "../numbers"
import AppContext from "../Context"
import { useStaticQuery, graphql } from "gatsby"

const ContainerMap = styled.div`
  width: 100%;
  position: relative;
  display: block;
  z-index: 20;
  height: auto;
  padding: 140px 0;
  @media only screen and (max-width: 900px) {
    padding: 10px 0;
  }
`
const BigText = styled.p`
  font-family: Archivo Black, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.S};
  margin-bottom: 15px;
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    line-height: 18px;
    font-family: Space Mono, sans-serif;
  }
`
const WideMap = styled.div`
  width: 100%;
  height: auto;
  padding: 0 2.5vw;
`
const SpecialMedium = styled.div`
  width: 20vw;
  max-width: 100%;
  > div {
    margin-top: 0vh;
    margin-bottom: 0vh;
    a {
      z-index: 20;
      width: 100%;
      height: 70px;
    }
  }
`
const EveryInfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  transform: translateY(-48%);
  @media only screen and (max-width: 900px) {
    align-items: center;
  }
  > div {
    max-width: 178px;
  }
`
const SpecialText = styled.p`
  font-family: Alata, sans-serif;
  font-weight: normal;
  font-size: calc(4px + 0.6vw);
  line-height: calc(4px + 0.6vw * 2);
  text-align: center;
  width: 15vw;
  transform: translateX(-10%);
  letter-spacing: 0.05em;
  color: #1f4ed2;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 21px;
    width: auto;
    transform: translate(0%, 29%);
  }
`
const LastInfos = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid #000000;
  margin: 2vh auto 5vh -1.5vw;
  padding: 0 4vw 2vh 4vw;
  @media only screen and (max-width: 900px) {
    margin: 70px auto 0 auto;
    width: 85%;
    background: #fff;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1366px) {
    margin-top: 80px;
  }
`
const TitleInfos = styled.h6`
  font-family: ${family.Alata};
  font-size: ${size.M};
  font-weight: normal;
  padding-top: 2vh;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 900px) {
    font-size: 16px;
    line-height: 22px;
    padding-top: 4vh;
    margin-bottom: 4vh;
  }
`
const InlineRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`
const BlockText = styled.p`
  font-family: Space Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.XXS};
  line-height: 20px;
  text-align: center;
  color: #000000;
  max-width: 350px;
  word-break: keep-all;
  wrap: no-wrap;
  width: 25%;
  @media only screen and (max-width: 900px) {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1367px) {
    width: 100%;
  }
`
const Button = styled.a`
  font-family: Alata, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.XS};
  text-align: center;
  letter-spacing: 0.1em;
  background: #ffffff;
  border: 2px solid #1f4ed2;
  box-sizing: border-box;
  border-radius: 5px;
  color: #1f4ed2;
  text-decoration: none;
  margin-bottom: 10px;
  padding: 10px 15px;
  cursor: none;
  transition: 0.3s ease background, 0.35s ease color;
  &:hover {
    background: #1f4ed2;
    color: #fff;
  }
  @media only screen and (max-width: 900px) {
    padding: 20px 15px;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1100px) {
    font-size: 0.6rem;
    letter-spacing: 0;
    width: 300px;
    margin-left: 8px;
    margin-right: 8px;
  }
`
const MobileMediumArticles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  div:not(:last-of-type) {
    margin: 50px 0 20px;
    width: 43%;
    height: 65px;
  }
  div:nth-child(3),
  div:nth-child(4) {
    margin: 20px 0 0 0;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    top: 80px;
  }
`
const ArticleContainer = styled.div`
  width: 70%;
  margin: 0 auto;

  @media screen and (max-width: 900px) and (max-height: 430px) {
    height: 240px;
  }
`
const Map = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          body {
            ... on PrismicIndexBodyInternational {
              primary {
                medium_text {
                  text
                }
                subtitle_inter {
                  text
                }
                title_1 {
                  text
                }
                title_2 {
                  text
                }
                url_medium {
                  url
                }
              }
              items {
                number {
                  text
                }
                text {
                  text
                }
              }
            }
          }
          more_infos {
            text
          }
          more_infos_text1 {
            text
          }
          more_infos_text2 {
            text
          }
        }
      }
    }
  `)
  const { isMobile } = useContext(AppContext)
  const prismic = data.prismicIndex.data
  return (
    <ContainerMap>
      <NumberStringHome>06.</NumberStringHome>
      <NumberMobile>
        <span>06.</span>
        <span>International</span>
      </NumberMobile>
      <TitleHome>
        {prismic.body[6].primary.title_1.text}{" "}
        <mark>{prismic.body[6].primary.title_2.text}</mark>
      </TitleHome>
      <BigText>{prismic.body[6].primary.subtitle_inter.text}</BigText>
      <WideMap>
        <Svgmap />
      </WideMap>
      {!isMobile && (
        <EveryInfosContainer>
          {prismic.body[6].items.map((item, i) => (
            <NumbersContainer key={i}>
              <Number>{item.number.text}</Number>
              <Explanation>{item.text.text}</Explanation>
            </NumbersContainer>
          ))}
          <SpecialMedium>
            <MediumArticles
              single={true}
              stretch={true}
              smallTop
              articles={[
                {
                  title: (
                    <NumbersContainer>
                      <SpecialText>
                        {prismic.body[6].primary.medium_text.text}
                      </SpecialText>
                    </NumbersContainer>
                  ),
                  link: `${prismic.body[6].primary.url_medium.url}`,
                },
              ]}
              circleColor="#BAFF0D"
            />
          </SpecialMedium>
        </EveryInfosContainer>
      )}
      {isMobile && (
        <MobileMediumArticles>
          <svg
            className="bulle-mobile-droite"
            style={{ position: "absolute", right: "-5%", top: "30%" }}
            width="291"
            height="401"
            viewBox="0 0 291 401"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.4469 242.58C21.7646 186.205 84.7798 158.363 124.517 118.361C165.604 77.0018 194.193 3.43527 251.924 11.5784C309.532 19.7043 320.78 96.1485 344.281 149.36C362.143 189.804 381.564 231.356 367.501 273.273C353.065 316.299 314.85 344.088 273.029 361.729C225.863 381.624 173.846 396.442 127.617 374.461C74.1883 349.057 23.1627 301.729 22.4469 242.58Z"
              fill="#F5FAFF"
            />
          </svg>
          <svg
            className="bulle-mobile-gauche"
            style={{ position: "absolute", left: "-10%", top: "70%" }}
            width="248"
            height="357"
            viewBox="0 0 248 357"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M161.049 91.6527C181.501 126.139 152.574 165.682 142.308 204.445C131.693 244.523 140.221 300.002 101.763 315.499C63.387 330.963 29.2948 287.867 -4.09007 263.437C-29.465 244.869 -56.1937 226.172 -62.4244 195.352C-68.8199 163.717 -55.1519 133.019 -35.657 107.292C-13.6705 78.2758 13.1092 50.6637 49.3985 47.7786C91.3397 44.4441 139.59 55.469 161.049 91.6527Z"
              fill="#F5FAFF"
            />
          </svg>
          <NumbersContainer>
            <Number>{prismic.body[6].items[0].number.text}</Number>
            <Explanation>{prismic.body[6].items[0].text.text}</Explanation>
          </NumbersContainer>
          <NumbersContainer>
            <Number>{prismic.body[6].items[1].number.text}</Number>
            <Explanation>{prismic.body[6].items[1].text.text}</Explanation>
          </NumbersContainer>
          <NumbersContainer className="marginTop">
            <Number>{prismic.body[6].items[2].number.text}</Number>
            <Explanation>{prismic.body[6].items[2].text.text}</Explanation>
          </NumbersContainer>
          <NumbersContainer className="marginTop">
            <Number>{prismic.body[6].items[3].number.text}</Number>
            <Explanation>{prismic.body[6].items[3].text.text}</Explanation>
          </NumbersContainer>
          <ArticleContainer>
            <MediumArticles
              className="single"
              right={true}
              articles={[
                {
                  title: (
                    <SpecialText>
                      {prismic.body[6].primary.medium_text.text}
                    </SpecialText>
                  ),
                  link: `${prismic.body[6].primary.url_medium.url}`,
                },
              ]}
              circleColor="#BAFF0D"
            />
          </ArticleContainer>
        </MobileMediumArticles>
      )}
      <LastInfos>
        <TitleInfos>{prismic.more_infos.text}</TitleInfos>
        <InlineRow>
          <BlockText>{prismic.more_infos_text1.text}</BlockText>
          {!isMobile && (
            <Button
              className="click"
              href="https://calendly.com/novlini/rdv-equipe-novlini"
            >
              discuter maintenant !
            </Button>
          )}
          <BlockText>{prismic.more_infos_text2.text}</BlockText>
          {isMobile && (
            <Button
              className="click"
              href="https://calendly.com/novlini/rdv-equipe-novlini"
            >
              discuter maintenant !
            </Button>
          )}
        </InlineRow>
      </LastInfos>
    </ContainerMap>
  )
}

export default Map
