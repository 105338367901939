import React, { useState } from "react"
import styled from "styled-components"
import { size } from "../../elements/font"
import { useStaticQuery, graphql } from "gatsby"

const BigContainer = styled.div`
  margin-top: 0px;
`
const RelativeContainer = styled.div`
  position: relative;
  width: calc(100% + 10vw - 20px * 2);
  height: calc(406px + 60px * 2);
  margin: 0 0 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  @media only screen and (max-width: 900px) {
    margin: 0;
    height: 406px;
  }
`
const List = styled.ul`
  position: absolute;
  display: block;
  margin: 60px 0 0;
  height: 406px;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0;
  @media only screen and (max-width: 900px) {
    margin: 0;
  }
`
const Card = styled.li`
  position: relative;
  width: 17vw;
  min-width: 240px;
  height: 90%;
  padding: 16px 32px;
  background: #dff7ff;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  margin: 0 20px;
  transition: 0.3s ease;
  &:nth-child(odd) {
    top: ${props => (props.Align ? "5%" : "0%")};
    @media only screen and (max-width: 900px) {
      top: ${props => (props.Align ? "5%" : "5%")};
    }
  }
  &:nth-child(even) {
    top: ${props => (props.Align ? "5%" : "10%")};
    @media only screen and (max-width: 900px) {
      top: ${props => (props.Align ? "5%" : "5%")};
    }
  }
  &:last-of-type {
    margin-right: 80px;
    @media only screen and (max-width: 900px) {
      margin-right: 10px;
    }
  }
`
const Number = styled.span`
  font-family: Alata, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 120px;
  display: flex;
  align-items: center;
  text-align: center;
  height: 50%;
  margin-bottom: 20px;
  width: fit-content;
  text-align: center;
  z-index: 1;
  color: rgba(0, 0, 0, 0.75);
  @media only screen and (max-width: 900px) {
    font-size: 21vw;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    font-size: 21vw;
  }
`
const Title = styled.span`
  font-family: Alata, sans-serif;
  font-size: ${size.M};
  line-height: 34px;
  color: #000;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #000;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 900px) {
    font-size: ${size.M};
  }
`
const Content = styled.p`
  font-family: Alata, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.XXS};
  line-height: 20px;
  margin-top: 10px;
  z-index: 1;
  align-self: flex-start;
`
const Date = styled.span`
  font-family: Alata, sans-serif;
  font-style: normal;
  font-weight: normal;
  align-self: flex-end;
  z-index: 1;
  font-size: ${size.XXXS};
`
const Paragraph = styled.p`
  font-family: Space Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.XS};
  line-height: calc(${size.XS} * 1.5);
  margin: 10px 0 1.45rem 0;
  letter-spacing: 0.05em;
  max-width: 850px;
  z-index: 1;
  mark {
    background: none;
    font-weight: 500;
    text-decoration: underline;
  }
`
const Question = styled.span`
  font-family: Archivo Black, sans-serif;
  font-style: normal;
  z-index: 1;
  font-weight: normal;
  font-size: ${size.S};
  line-height: 27px;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
`

const SliderCard = () => {
  const [Align, setAlign] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          body {
            ... on PrismicIndexBodyNovliniScore2 {
              id
              items {
                number {
                  text
                }
                text {
                  text
                }
                title {
                  text
                }
                animation_chiffres {
                  url
                }
              }
              primary {
                subtitle_card_slider {
                  text
                }
                title_card_slider {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data
  return (
    <div className="slider" style={{ position: "relative", zIndex: 1 }}>
      <div></div>
      <BigContainer>
        <Question>{prismic.body[5].primary.title_card_slider.text}</Question>
        <Paragraph>
          {prismic.body[5].primary.subtitle_card_slider.text}
        </Paragraph>
        <RelativeContainer>
          <List className="horizontal-scroll">
            {prismic.body[5].items.map((item, index) => (
              <Card
                onMouseEnter={() => {
                  setAlign(true)
                }}
                onMouseLeave={() => {
                  setAlign(false)
                }}
                Align={Align}
                key={index}
              >
                {/* <VideoAnimation autoPlay loop muted>
                                    <source src={item.animation_chiffres.url} type="video/mp4" />
                                </VideoAnimation> */}
                <Number>{item.number.text}</Number>
                <Title>{item.title.text}</Title>
                <Content>{item.text.text}</Content>
                <Date>
                  0{index + 1}/ 0{prismic.body[5].items.length}
                </Date>
              </Card>
            ))}
          </List>
        </RelativeContainer>
      </BigContainer>
    </div>
  )
}

export default SliderCard
