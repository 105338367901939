import React, { useEffect, useContext, useState } from "react"
import styled, { keyframes } from "styled-components"
import AppContext from "../Context"
import colors from "../../elements/color"
import { size } from "../../elements/font"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { TitleHome, NumberMobile } from "../../elements/Text"
import RouletteSound from "../../sound/roulette.mp3"

const ArrowAnimation = keyframes`
    0% {
        transform: translateX(0%)
    }
    25% {
        transform: translateX(10%)
    }
    50% {
        transform: translateX(0%)
    }
    75% {
        transform: translateX(-10%)
    }
    100% {
        transform: translateX(0%)
    }
`
const RouletteContainer = styled.div`
  padding-top: 140px;
  width: 100%;
  div#my-sticky-element {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 0px;
    div#my-sticky-element {
      height: auto;
    }
  }
`
const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.25s ease opacity;
  margin: 0 auto;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`
const NumberCounter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 50px;
  transform: translateX(-1.65vw);
  @media only screen and (max-width: 900px) {
    margin: 0 10px;
  }
`
const InfoCounter = styled.span`
  font-family: Space Mono, sans-serif;
  font-size: ${size.XS};
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
`
const Numbers = styled.span`
  padding: 5px 20px 5px 45px;
  width: 152px;
  position: relative;
  border: 2px solid #000000;
  box-sizing: border-box;
  margin: 8px 0;
  &::after {
    content: "€";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-family: Space Mono, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: ${size.M};
    letter-spacing: 0.1em;
  }
  @media only screen and (max-width: 900px) {
    width: 135px;
    font-size: ${size.SSM};
    padding: 5px 10px 5px 35px;
    border: 1px solid #000000;
    &::after {
      font-size: 28px;
      left: 5px;
    }
  }
`
const RouletteLogo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 25px;
  width: 100%;
  transform: translateX(-1.65vw);
  transition: 0.25s ease opacity;
  @media only screen and (max-width: 900px) {
    width: calc(100% + 4vw);
    transform: translateX(-3vw);
    margin: 40px auto 20px;
  }
`
const ArrowLeft = styled.div`
  position: relative;
  min-width: 50px;
  animation: ${ArrowAnimation} 4s linear normal infinite;
  svg {
    width: 50px;
  }
  @media only screen and (max-width: 900px) {
    width: 8%;
    min-width: 10px;
    svg {
      width: 100%;
    }
  }
`
const ArrowRight = styled.div`
  position: relative;
  min-width: 50px;
  animation: ${ArrowAnimation} 4s linear reverse infinite;
  svg {
    width: 50px;
  }
  @media only screen and (max-width: 900px) {
    width: 8%;
    min-width: 10px;
    svg {
      width: 100%;
    }
  }
`
const RouletteColumn = styled.div`
  border: 3px solid #000000;
  border-radius: 30px;
  position: relative;
  min-width: 144px;
  width: 35%;
  max-width: 205px;
  height: 430px;
  margin: 0 30px;
  padding: 30px 20px;
  overflow: hidden;
  background: #fff;
  &::after {
    content: "";
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 15px solid #dfdfdf;
    border-radius: 30px;
  }
  @media only screen and (max-width: 900px) {
    margin: 0 5px;
    min-width: 60px;
    border: 1px solid #000000;
    padding: 10px 5px;
    height: 198px;
    border-radius: 10px;
    &::after {
      content: "";
      z-index: 200;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 5px solid #dfdfdf;
      border-radius: 10px;
    }
  }
`
const EveryLogos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    > div:first-of-type {
        margin 0 0 55px 0;
    }
    @media only screen and (max-width: 900px) {
        > div:first-of-type {
            margin 0 0 35px 0;
        }
    }
`
const LogoContainer = styled.div`
  margin: 55px 0;
  width: 75%;
  svg {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 900px) {
    margin: 22.5px 0;
  }
`
const Percentage = styled.span`
  display: block;
  margin: 10px auto;
  transform: translateX(-1.65vw);
  font-family: Space Mono, sans-serif;
  font-size: ${size.XS};
  transition: 0.25s ease opacity;
  text-align: center;
  letter-spacing: 0.2em;
  @media only screen and (max-width: 900px) {
    transform: translateX(0vw);
  }
`
const LinkTools = styled.a`
  padding: 5px 75px;
  font-family: Alata, sans-serif;
  border: 3px solid ${colors.darkBlue};
  background: #fff;
  letter-spacing: 0.1em;
  display: block;
  margin: 5px auto 20px;
  font-size: ${size.XS};
  color: ${colors.darkBlue};
  text-decoration: none;
  transition: 0.3s ease;
  z-index: 5;
  transform: translateX(-1.65vw);
  &:hover {
    background: ${colors.darkBlue};
    color: #fff;
  }
  @media only screen and (max-width: 900px) {
    padding: 5px 20px;
    font-size: 10px;
    transform: translateX(0vw);
    margin: 10px auto 60px;
    border: 1.5px solid ${colors.darkBlue};
  }
`
const Round = styled.div`
  width: 20px;
  height: 20px;
  background: #d9d9d9;
  border: 2px solid #000000;
  border-radius: 50px;
  @media only screen and (max-width: 900px) {
    height: 11px;
    width: 15px;
    border: 1px solid #000000;
  }
`
const LastSentence = styled.p`
  font-family: Archivo Black, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.S};
  line-height: 35px;
  text-align: center;
  display: block;
  position: relative;
  width: 100%;
  min-width: 300px;
  margin: 20vh auto 100px;
  text-transform: uppercase;
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    width: 100%;
    line-height: 20px;
    margin: 30px auto 50px;
  }
`
const StartRoulette = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  transition: 0.3s ease;
  transform: translate(calc(-50% - 1.65vw), 64%);
  z-index: 300;
  padding: 10px 25px;
  border: 3px solid ${colors.darkBlue};
  background: #fff;
  color: ${colors.darkBlue};
  transition: 0.25s ease opacity;
  opacity: ${props => (props.visibleBtn ? 1 : 0)};
  pointer-events: ${props => (props.visibleBtn ? "all" : "none")};
  &:hover {
    background: ${colors.darkBlue};
    color: #fff;
  }
  @media only screen and (max-width: 900px) {
    transform: translate(calc(-50% - 1.65vw), -66%);
  }
`
const Roulette = ({ bouton }) => {
  const { isMobile } = useContext(AppContext)
  const [visibleBtn, setVisibleBtn] = useState(true)
  const data = useStaticQuery(graphql`
    query {
      amazon: file(relativePath: { eq: "roulette/first/amazon.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      bingads: file(relativePath: { eq: "roulette/first/bingads.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      discord: file(relativePath: { eq: "roulette/first/discord.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecosia: file(relativePath: { eq: "roulette/first/ecosia.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebook: file(relativePath: { eq: "roulette/first/facebook.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      google: file(relativePath: { eq: "roulette/first/google.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      instagram: file(relativePath: { eq: "roulette/first/instagram.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      linkedin: file(relativePath: { eq: "roulette/first/linkedin.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      outbrain: file(relativePath: { eq: "roulette/first/outbrain.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      pinterest: file(relativePath: { eq: "roulette/first/pinterest.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      snapchat: file(relativePath: { eq: "roulette/first/snapchat.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      spotify: file(relativePath: { eq: "roulette/first/spotify.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      taboola: file(relativePath: { eq: "roulette/first/taboola.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      tiktok: file(relativePath: { eq: "roulette/first/tiktok.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter: file(relativePath: { eq: "roulette/first/twitter.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      wazelocal: file(relativePath: { eq: "roulette/first/wazelocal.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatsapp: file(relativePath: { eq: "roulette/first/whatsapp.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      youtube: file(relativePath: { eq: "roulette/first/youtube.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      active: file(relativePath: { eq: "roulette/second/active.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      optimizely: file(relativePath: { eq: "roulette/second/optimizely.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      airtable: file(relativePath: { eq: "roulette/second/airtable.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      apollo: file(relativePath: { eq: "roulette/second/apollo.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      campaign: file(relativePath: { eq: "roulette/second/campaign.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      hubspot: file(relativePath: { eq: "roulette/second/hubspot.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      hunter: file(relativePath: { eq: "roulette/second/hunter.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      insightly: file(relativePath: { eq: "roulette/second/insightly.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      mailchimp: file(relativePath: { eq: "roulette/second/mailchimp.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      mixmax: file(relativePath: { eq: "roulette/second/mixmax.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      phantom: file(relativePath: { eq: "roulette/second/phantom.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      pipedrive: file(relativePath: { eq: "roulette/second/pipedrive.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      prospectin: file(relativePath: { eq: "roulette/second/prospectin.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      salesforce: file(relativePath: { eq: "roulette/second/salesforce.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      segment: file(relativePath: { eq: "roulette/second/segment.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      snovio: file(relativePath: { eq: "roulette/second/snovio.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      supermetrics: file(
        relativePath: { eq: "roulette/second/supermetrics.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      upwork: file(relativePath: { eq: "roulette/second/upwork.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      zendesk: file(relativePath: { eq: "roulette/second/zendesk.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      buffer: file(relativePath: { eq: "roulette/third/buffer.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      buzzsumo: file(relativePath: { eq: "roulette/third/buzzsumo.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      calendly: file(relativePath: { eq: "roulette/third/calendly.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      docusign: file(relativePath: { eq: "roulette/third/docusign.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      figma: file(relativePath: { eq: "roulette/third/figma.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotjar: file(relativePath: { eq: "roulette/third/hotjar.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      iftt: file(relativePath: { eq: "roulette/third/iftt.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      instapage: file(relativePath: { eq: "roulette/third/instapage.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      loom: file(relativePath: { eq: "roulette/third/loom.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      meero: file(relativePath: { eq: "roulette/third/meero.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      monday: file(relativePath: { eq: "roulette/third/monday.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      play: file(relativePath: { eq: "roulette/third/play.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      slack: file(relativePath: { eq: "roulette/third/slack.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      squarespace: file(
        relativePath: { eq: "roulette/third/squarespace.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      trello: file(relativePath: { eq: "roulette/third/trello.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      typeform: file(relativePath: { eq: "roulette/third/typeform.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      unbounce: file(relativePath: { eq: "roulette/third/unbounce.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      webflow: file(relativePath: { eq: "roulette/third/webflow.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      zapier: file(relativePath: { eq: "roulette/third/zapier.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
      zoom: file(relativePath: { eq: "roulette/third/zoom.png" }) {
        childImageSharp {
          fluid(maxHeight: 60) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const rollRoulette = () => {
    let x = 1
    const column1 = document.querySelector("#column1")
    const column2 = document.querySelector("#column2")
    const column3 = document.querySelector("#column3")
    const invested = document.querySelector("#invested")
    const earned = document.querySelector("#earned")
    const percentageref = document.querySelector("#percentageref")
    const numbers = document.querySelector(".numbersInvest")
    const rouletteAll = document.querySelector(".rouletteAll")
    const selectedColumn = document.querySelectorAll(".selected")
    column1.style.opacity = 1
    column2.style.opacity = 1
    column3.style.opacity = 1
    invested.style.opacity = 1
    earned.style.opacity = 1
    selectedColumn.forEach(col => {
      col.style.opacity = 1
    })
    numbers.style.opacity = 1
    percentageref.style.opacity = 1
    rouletteAll.style.opacity = 1
    const audioRoulette = new Audio(RouletteSound)
    if (!isMobile) {
      audioRoulette.play()
    }
    const intervalLoop = () => {
      x += 1
      percentageref.innerText = `${Math.round(x)}%`
      earned.innerText =
        Math.round(((x + Math.random()) / 100) * 102000) / 1000 >= 102
          ? "102.000"
          : Math.round(((x + Math.random()) / 100) * 102000) / 1000
      invested.innerText =
        Math.round(((x + Math.random()) / 100) * 35000) / 1000 >= 35
          ? "35.000"
          : Math.round(((x + Math.random()) / 100) * 35000) / 1000
      if (!isMobile) {
        column1.style.transform = `translate3d(0px, ${
          -84.9 > Math.round(x * -1.667)
            ? -84.9
            : -18 <= Math.round(x * -1.667)
            ? -18
            : Math.round(x * -1.667)
        }%, 0px)`
        column2.style.transform = `translate3d(0px, ${
          -86.3 > Math.round(x * -1.334)
            ? -86.3
            : -15 <= Math.round(x * -1.334)
            ? -15
            : Math.round(x * -1.334)
        }%, 0px)`
        column3.style.transform = `translate3d(0px, ${
          -86.3 > Math.round(x * -1)
            ? -86.3
            : -16 <= Math.round(x * -1)
            ? -16
            : Math.round(x * -1)
        }%, 0px)`
        if (x < 100) {
          setTimeout(() => {
            requestAnimationFrame(intervalLoop)
          }, ((4 * Math.sin(x / 2)) / (x / 2)) * Math.PI + 1)
        }
      } else {
        if (/Android/i.test(navigator.userAgent)) {
          window.navigator.vibrate([
            100,
            30,
            100,
            30,
            100,
            30,
            200,
            30,
            200,
            30,
            200,
            30,
            100,
            30,
            100,
            30,
            100,
          ])
          audioRoulette.play()
        }
        column1.style.transform = `translate3d(0px, ${
          -83.6 > Math.round(x * -1.667)
            ? -83.6
            : -18 <= Math.round(x * -1.667)
            ? -18
            : Math.round(x * -1.667)
        }%, 0px)`
        column2.style.transform = `translate3d(0px, ${
          -85 > Math.round(x * -1.334)
            ? -85
            : -15 <= Math.round(x * -1.334)
            ? -15
            : Math.round(x * -1.334)
        }%, 0px)`
        column3.style.transform = `translate3d(0px, ${
          -85.1 > Math.round(x * -1)
            ? -85.1
            : -16 <= Math.round(x * -1)
            ? -16
            : Math.round(x * -1)
        }%, 0px)`
        if (x < 100) {
          setTimeout(() => {
            requestAnimationFrame(intervalLoop)
          }, ((4 * Math.sin(x / 2)) / (x / 2)) * Math.PI + 1)
        }
      }
    }
    intervalLoop()
  }
  useEffect(() => {
    const windowGlobal = typeof window !== "undefined" && window
    const column1 = document.querySelector("#column1")
    const column2 = document.querySelector("#column2")
    const column3 = document.querySelector("#column3")
    const invested = document.querySelector("#invested")
    const earned = document.querySelector("#earned")
    const selectedColumn = document.querySelectorAll(".selected")
    const percentageref = document.querySelector("#percentageref")
    column1.style.opacity = 0.5
    column2.style.opacity = 0.5
    column3.style.opacity = 0.5
    invested.style.opacity = 0.5
    earned.style.opacity = 0.5
    selectedColumn.forEach(col => {
      col.style.opacity = 0.5
    })
    percentageref.style.opacity = 0.5
  }, [])
  return (
    <RouletteContainer>
      <div id="my-sticky-element">
        {isMobile ? (
          <svg
            className="bulle-mobile-droite"
            style={{
              position: "absolute",
              right: "0",
              bottom: "5%",
              zIndex: "0",
            }}
            width="241"
            height="302"
            viewBox="0 0 241 302"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M203.783 49.9413C244.641 72.5896 255.142 124.518 244.417 169.889C234.217 213.036 200.111 245.721 156.114 251.598C105.967 258.295 48.106 245.601 29.3496 198.614C11.6412 154.252 52.8252 114.762 89.137 83.8125C122.64 55.2575 165.207 28.5573 203.783 49.9413Z"
              fill="#F5FAFF"
            />
          </svg>
        ) : (
          <svg
            className="bulle-home"
            style={{
              transform: "scale(1.2)",
              position: "absolute",
              left: "-10%",
              bottom: "-50%",
              width: "60%",
              zIndex: "0",
            }}
            width="861"
            height="818"
            viewBox="0 0 861 818"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M371.94 0.963524C503.683 12.7085 553.749 166.238 637.706 268.364C724.513 373.957 889.453 458.027 856.777 590.721C824.169 723.134 643.08 731.279 513.316 773.492C414.687 805.576 313.104 841.035 218.596 798.324C121.588 754.483 65.7768 658.797 34.5042 557.103C-0.765093 442.412 -23.0349 317.608 39.2168 215.009C111.164 96.4296 233.713 -11.3595 371.94 0.963524Z"
              fill="#F5FAFF"
            />
          </svg>
        )}
        <TitleHome paddingTop>
          <NumberMobile>
            <span>Nos outils</span>
          </NumberMobile>
          La <mark>fameuse</mark> roulette
        </TitleHome>
        <CounterContainer className="numbersInvest">
          <NumberCounter>
            <InfoCounter>invested</InfoCounter>
            <Numbers id="invested">0</Numbers>
          </NumberCounter>
          <NumberCounter>
            <InfoCounter>earned</InfoCounter>
            <Numbers id="earned">0</Numbers>
          </NumberCounter>
        </CounterContainer>
        <StartRoulette
          onClick={() => {
            setVisibleBtn(false)
            rollRoulette()
          }}
          visibleBtn={visibleBtn}
          className="click"
        >
          {bouton}
        </StartRoulette>
        <RouletteLogo className="rouletteAll">
          <ArrowLeft className="selected">
            <svg
              width="61"
              height="67"
              viewBox="0 0 61 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter_arrow_left)">
                <path
                  d="M57.6157 25.1519C60.9491 27.0764 60.9491 31.8876 57.6157 33.8121L15.5002 58.1276C12.1669 60.0521 8.00018 57.6464 8.00018 53.7974L8.00018 5.16661C8.00018 1.31761 12.1669 -1.08803 15.5002 0.836475L57.6157 25.1519Z"
                  fill="#DFF7FF"
                />
                <path
                  d="M56.8657 26.4509C59.1991 27.7981 59.1991 31.166 56.8657 32.5131L14.7502 56.8285C12.4169 58.1757 9.50018 56.4917 9.50018 53.7974L9.50018 5.16661C9.50018 2.4723 12.4169 0.788364 14.7502 2.13551L56.8657 26.4509Z"
                  stroke="black"
                  strokeWidth="3"
                />
              </g>
              <defs>
                <filter
                  id="filter_arrow_left"
                  x="0"
                  y="0.15918"
                  width="60.1155"
                  height="66.6458"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx="-6" dy="6" />
                  <feGaussianBlur stdDeviation="1" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </ArrowLeft>
          <RouletteColumn className="selected">
            <EveryLogos id="column1">
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.google.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.facebook.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.tiktok.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.instagram.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.snapchat.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.linkedin.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.whatsapp.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.youtube.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.pinterest.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.twitter.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.bingads.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.discord.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.taboola.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.outbrain.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.ecosia.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.amazon.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.wazelocal.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.spotify.childImageSharp.fluid}
                />
              </LogoContainer>
            </EveryLogos>
          </RouletteColumn>
          <Round className="selected" />
          <RouletteColumn className="selected">
            <EveryLogos id="column2">
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.mailchimp.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.phantom.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.hubspot.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.hunter.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.prospectin.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.pipedrive.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.segment.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.supermetrics.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.snovio.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.upwork.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.apollo.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.zendesk.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.mixmax.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.insightly.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.salesforce.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.active.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.optimizely.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.airtable.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.campaign.childImageSharp.fluid}
                />
              </LogoContainer>
            </EveryLogos>
          </RouletteColumn>
          <Round className="selected" />
          <RouletteColumn className="selected">
            <EveryLogos id="column3">
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.zapier.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img loading="eager" fluid={data.iftt.childImageSharp.fluid} />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.typeform.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.webflow.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.squarespace.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.instapage.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.unbounce.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.buzzsumo.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.hotjar.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img loading="eager" fluid={data.slack.childImageSharp.fluid} />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.monday.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.calendly.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img loading="eager" fluid={data.zoom.childImageSharp.fluid} />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.buffer.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img loading="eager" fluid={data.meero.childImageSharp.fluid} />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.docusign.childImageSharp.fluid}
                />
              </LogoContainer>
              <LogoContainer>
                <Img loading="eager" fluid={data.loom.childImageSharp.fluid} />
              </LogoContainer>
              <LogoContainer>
                <Img loading="eager" fluid={data.play.childImageSharp.fluid} />
              </LogoContainer>
              <LogoContainer>
                <Img loading="eager" fluid={data.figma.childImageSharp.fluid} />
              </LogoContainer>
              <LogoContainer>
                <Img
                  loading="eager"
                  fluid={data.trello.childImageSharp.fluid}
                />
              </LogoContainer>
            </EveryLogos>
          </RouletteColumn>
          <ArrowRight className="selected">
            <svg
              width="61"
              height="67"
              viewBox="0 0 61 67"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter_arrow_right)">
                <path
                  d="M2.87403 33.8121C-0.459306 31.8876 -0.459311 27.0763 2.87402 25.1518L44.9896 0.836422C48.3229 -1.08808 52.4896 1.31755 52.4896 5.16655V53.7974C52.4896 57.6464 48.3229 60.052 44.9896 58.1275L2.87403 33.8121Z"
                  fill="#DFF7FF"
                />
                <path
                  d="M3.62403 32.5131C1.29069 31.1659 1.29069 27.798 3.62402 26.4509L45.7396 2.13546C48.0729 0.788311 50.9896 2.47225 50.9896 5.16655V53.7974C50.9896 56.4917 48.0729 58.1756 45.7396 56.8285L3.62403 32.5131Z"
                  stroke="black"
                  strokeWidth="3"
                />
              </g>
              <defs>
                <filter
                  id="filter_arrow_right"
                  x="0.374023"
                  y="0.159058"
                  width="60.1155"
                  height="66.6458"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx="6" dy="6" />
                  <feGaussianBlur stdDeviation="1" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </ArrowRight>
        </RouletteLogo>
        <Percentage id="percentageref">0%</Percentage>
        <LinkTools target="_blank" href="/outils" className="click">
          voir la liste de tous les outils maîtrisés
        </LinkTools>
        {isMobile && (
          <LastSentence>
            “Avec Novlini, automatisez la production de contenus pour vous
            adresser correctement à vos cibles”
          </LastSentence>
        )}
      </div>
    </RouletteContainer>
  )
}

export default Roulette
