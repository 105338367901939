import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { size } from "../elements/font"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bonjour from "../components/bonjour"
import Popup from "../components/connexion_popup"
import WeAre from "../components/weare"
import Numbers from "../components/numbers"
import NewGen from "../components/newgen"
import Suivi from "../components/suivi"
import Contenus from "../components/contenus"
import Map from "../components/map"
import AppContext from "../components/Context"
import { useStaticQuery, graphql } from "gatsby"

const Bg = styled.div`
  background: #fff;
  scroll-behavior: smooth;
`
const Container = styled.div`
  padding: 0 5vw 0 8.5vw;
  div.changeHeaderString {
    position: relative;
  }
  @media only screen and (max-width: 900px) {
    padding: 0 2vw 0 5vw;
  }
`
const AnimContainer = styled.div`
  width: 60%;
  min-width: 260px;
  max-width: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: Archivo Black, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: ${size.L};
    letter-spacing: 0.1em;
    margin: 0 12px 0 0;
    white-space: nowrap;
  }
  @media only screen and (max-width: 900px) {
    width: 82%;
    span {
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 340px) {
    width: 89%;
    span {
      font-size: ${size.SM};
    }
  }
`
const ChangeWord = styled.mark`
  background: none;
  color: #1f4ed2;
  white-space: nowrap;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    background: #000;
    top: 98%;
    right: 1%;
  }
  @media only screen and (max-width: 900px) {
    &::before {
      height: 3px;
    }
  }
`
const IndexPage = ({ path }) => {
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          page_title {
            text
          }
          page_description {
            text
          }
          og_image {
            url
          }
          quote_footer {
            text
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data
  const [scroll, setScroll] = useState(false)
  const ible = [
    "flex",
    "sens",
    "tang",
    "access",
    "imprévis",
    "incred",
    "terr",
    "lis",
    "biocompat",
    "compréhens",
    "dispon",
    "irrésist",
    "indestruct",
  ]
  const arrayString = [
    "Notre mission",
    "Présentation",
    "Chiffres clés",
    "Manifesto",
    "Notre jardin",
    "Espace client dédié",
    "Nos outils",
    "Novlini score",
    "International",
  ]
  const { isMobile, isTablet } = useContext(AppContext)

  useEffect(() => {
    const bonjour = document.querySelector(".bonjour-section")
    window.addEventListener("scroll", () => {
      if (
        bonjour.getBoundingClientRect().y - 200 <
        -1 * bonjour.getBoundingClientRect().height
      ) {
        setScroll(true)
      } else if (
        bonjour.getBoundingClientRect().y - 200 >
        -1 * bonjour.getBoundingClientRect().height
      ) {
        setScroll(false)
      }
    })
    let toChange = document.querySelector(".changeWords")
    let value = 14
    const loop = () => {
      if (toChange !== null) {
        toChange.innerHTML = ible[value - 2]
        value--
        let timeSpeed = 0
        if (value - 2 >= 0) {
          timeSpeed =
            Math.sqrt(value * 0.2) +
            Math.log(value * 0.9) * Math.tanh(value - 0.01)
          setTimeout(() => window.requestAnimationFrame(loop), timeSpeed * 95)
        } else {
          setTimeout(() => {
            value = 14
            loop()
          }, 100)
        }
      } else {
        return
      }
    }
    loop()
    return () => {
      toChange = null
    }
  }, [ible])

  return (
    <Layout
      headerString={arrayString}
      hasScrolled={scroll}
      footerText={prismic.quote_footer.text}
    >
      <Bg>
        <SEO
          title={prismic.page_title.text}
          description={prismic.page_description.text}
          image={prismic.og_image.url}
          location={path}
        />
        <Bonjour />
        <Container>
          <div id="mission" className="changeHeaderString">
            {isMobile ? (
              <>
                <svg
                  className="bulle-mobile-droite-soft"
                  style={{ position: "absolute", top: "20%", right: "0" }}
                  width="308"
                  height="622"
                  viewBox="0 0 308 622"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M277.672 1.00173C335.244 -2.85691 399.832 3.05233 441.148 43.3433C481.201 82.4037 462.833 149.508 483.338 201.568C506.027 259.173 578.101 301.508 566.808 362.384C555.623 422.674 477.444 439.63 431.618 480.36C378.644 527.443 347.487 607.208 277.672 619.368C205.915 631.865 124.372 598.853 79.1346 541.752C36.1211 487.458 70.8903 408.051 56.5636 340.274C44.5395 283.391 -12.3817 235.177 2.4468 178.96C17.3591 122.426 80.2842 95.9803 129.374 64.2392C175.636 34.3269 222.71 4.68543 277.672 1.00173Z"
                    fill="#F5FAFF"
                  />
                </svg>
                <svg
                  className="bulle-mobile-gauche"
                  style={{ position: "absolute", top: "90%", left: "-10%" }}
                  width="256"
                  height="301"
                  viewBox="0 0 256 301"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-106.957 157.493C-108.859 84.6151 -47.3474 29.3688 22.1573 7.85959C88.2558 -12.5955 159.218 7.38254 201.964 61.8924C250.685 124.02 279.438 211.88 231.108 274.313C185.479 333.259 71.3748 278.819 1 254.5C-63.9308 232.063 -105.162 226.302 -106.957 157.493Z"
                    fill="#F5FAFF"
                  />
                </svg>
              </>
            ) : (
              <svg
                className="bulle-home"
                style={{
                  transform: "scale(1.2)",
                  position: "absolute",
                  top: "-10%",
                  right: "-10%",
                  width: "100%",
                }}
                width="1604"
                height="2000"
                viewBox="0 0 1604 2000"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M893.146 3.221C1078.33 -9.18619 1286.08 9.81456 1418.97 139.367C1547.81 264.964 1488.73 480.733 1554.68 648.128C1627.66 833.354 1859.49 969.48 1823.16 1165.22C1787.19 1359.08 1535.72 1413.6 1388.32 1544.57C1217.93 1695.96 1117.71 1952.44 893.146 1991.54C662.337 2031.72 400.049 1925.57 254.54 1741.97C116.185 1567.39 228.022 1312.06 181.94 1094.13C143.263 911.225 -39.8264 756.197 7.87024 575.435C55.8363 393.651 258.238 308.618 416.138 206.557C564.94 110.376 716.359 15.0657 893.146 3.221Z"
                  fill="#F5FAFF"
                />
              </svg>
            )}
            <WeAre>
              <AnimContainer>
                <span>We are</span>
                <span>
                  <ChangeWord className="changeWords">{ible[12]}</ChangeWord>
                  ible
                </span>
              </AnimContainer>
            </WeAre>
          </div>
          <div
            style={{ position: "relative" }}
            id="chiffres"
            className="changeHeaderString"
          >
            <Numbers />
          </div>
          <div id="jardin" className="changeHeaderString">
            <NewGen />
          </div>
          <div id="client" className="changeHeaderString">
            <Suivi />
          </div>
          <div id="novlini" className="changeHeaderString">
            <Contenus />
          </div>
          <div id="international" className="changeHeaderString">
            <Map />
          </div>
        </Container>
      </Bg>
    </Layout>
  )
}

export default IndexPage
