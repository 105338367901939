import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import colors from "../../elements/color"
import { size } from "../../elements/font"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 640px;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    height: 50%;
    width: 1px;
    border-left: ${props =>
      props.showBorder
        ? `2px dotted ${colors.lightBlue}`
        : "2px dotted #1aa1fb00"};
    z-index: 5;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 50%;
    height: 1px;
    border-top: ${props =>
      props.showBorder
        ? `2px dotted ${colors.lightBlue}`
        : "2px dotted #1aa1fb00"};
    z-index: 5;
  }
  @media screen and (max-width: 815px) {
    min-height: 0%;
  }
`
const BonjourText = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1040px;
  max-height: 583px;
  width: 54%;
  min-width: 600px;
  height: 53%;
  transition: 0.3s ease border;
  background: #fff;
  z-index: 20;
  border: ${props =>
    props.showBorder ? `2px solid ${colors.lightBlue}` : "2px solid #1aa1fb00"};
  &.null {
    pointer-events: none;
  }
  &.clickable {
    pointer-events: all;
  }
  div.square {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: ${props => (props.showBorder ? `#fff` : "none")};
    border: ${props =>
      props.showBorder
        ? `2px solid ${colors.lightBlue}`
        : "2px solid #1aa1fb00"};
    & + div.square {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background: ${props => (props.showBorder ? `#fff` : "none")};
      border: ${props =>
        props.showBorder
          ? `2px solid ${colors.lightBlue}`
          : "2px solid #1aa1fb00"};
      & + div.square {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: ${props => (props.showBorder ? `#fff` : "none")};
        border: ${props =>
          props.showBorder
            ? `2px solid ${colors.lightBlue}`
            : "2px solid #1aa1fb00"};
        & + div.square {
          position: absolute;
          top: 100%;
          left: 100%;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 10px;
          background: ${props => (props.showBorder ? `#fff` : "none")};
          border: ${props =>
            props.showBorder
              ? `2px solid ${colors.lightBlue}`
              : "2px solid #1aa1fb00"};
        }
      }
    }
  }
  @media screen and (max-width: 815px) {
    width: 80%;
    height: 43%;
    min-width: 200px;

    border: ${props =>
      props.showBorder
        ? `2px solid ${colors.lightBlue}`
        : "0px solid #1aa1fb00"};
  }
`
const Emoji = styled.span`
  color: #000000;
  max-width: 83.5%;
  position: absolute;
  pointer-events: none;
  font-family: "Archivo Black", sans-serif;
  height: fit-content;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 0 5px;
  font-size: 70px;
  line-height: calc(70px * 1.35);
  background: none;
  opacity: 0;
  z-index: 10;
  @media screen and (max-width: 815px) {
    font-size: ${size.M};
    line-height: 32px;
  }
`
const Text = styled.span`
  color: #000000;
  min-height: 22px;
  word-break: keep-all;
  max-width: 83.5%;
  position: relative;
  font-family: "Archivo Black", sans-serif;
  height: fit-content;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 0 5px;
  font-size: 70px;
  line-height: calc(70px * 1.35);
  transition: 0.1s ease-out background;
  background: none;
  &.selected {
    background: #b3defe;
  }
  @media screen and (max-width: 815px) {
    font-size: ${size.M};
    line-height: 32px;
  }
`
const InputUser = styled.input`
  color: #000000;
  word-break: keep-all;
  position: absolute;
  border: none;
  background: none;
  font-family: "Archivo Black", sans-serif;
  outline: none;
  height: fit-content;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`
const TypingIndicator = styled.span`
  color: rgb(66, 66, 66);
  position: relative;
  bottom: 4px;
  font-weight: 100;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
  @keyframes typedjsBlink {
    50% {
      opacity: 0;
    }
  }
`
const Sizes = styled.span`
  background: ${colors.lightBlue};
  position: absolute;
  top: 78.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  padding: 0 5px;
  font-family: sans-serif;
  color: #fff;
  font-size: 10px;
  opacity: 0;
  transition: 0.3s ease opacity;
  opacity: ${props => (props.showBorder ? "1" : "0")};
  pointer-events: ${props => (props.showBorder ? "all" : "none")};
  @media screen and (max-width: 815px) {
    opacity: ${props => (props.showBorder ? "0" : "0")};
    pointer-events: ${props => (props.showBorder ? "none" : "none")};
  }
`
const Acquisition = styled.span`
  color: #000;
  position: absolute;
  top: 38%;
  right: 0.5%;
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 0.05em;
  font-size: ${size.XXXS};
  line-height: 18px;
  letter-spacing: 0.1em;
  transform: rotate(90deg) scale(-1);
  transform-origin: bottom right;
  @media screen and (max-width: 815px) {
    display: none;
  }
`
const UpsideDown = styled.span`
  color: #000;
  position: absolute;
  top: 1%;
  right: 6%;
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 0.05em;
  font-size: ${size.XXXS};
  line-height: 18px;
  letter-spacing: 0.1em;
  transform: rotate(180deg);
  @media screen and (max-width: 815px) {
    display: none;
  }
`
const RandomText = styled.span`
  color: #000;
  position: absolute;
  top: 9%;
  left: 0.5%;
  font-family: "Roboto Mono", sans-serif;
  letter-spacing: 1px;
  font-size: ${size.XXXS};
  line-height: 19px;
  transform: rotate(90deg);
  transform-origin: bottom left;
  @media screen and (max-width: 815px) {
    display: none;
  }
`
const Summary = styled.ul`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 500px;
  list-style: none;
  bottom: 4px;
  left: 5vw;
  z-index: 1;
  li {
    padding: 0 0 20px 0;
    width: 154px;
    margin: 0;
    font-family: "Alata", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: ${size.XXXXS};
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    a {
      width: 100%;
      color: #000;
      font-family: "Alata", sans-serif;
      font-size: ${size.XXXXS};
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      white-space: nowrap;
      span:first-of-type {
        margin: 0 25px 0 0;
        @media screen and (max-width: 815px) {
          margin: 0 5px 0 0;
        }
      }
      span:last-of-type {
        position: relative;
        overflow: hidden;
        padding-bottom: 5px;
        &::after {
          content: "";
          position: absolute;
          transition: 0.3s ease transform;
          will-change: transform;
          bottom: 0;
          left: 0;
          height: 1.5px;
          width: 100%;
          transform-origin: right;
          background: #000;
          transform: scaleX(0);
        }
      }
      &:hover {
        span:last-of-type {
          &::after {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
    }
  }
  @media screen and (max-width: 815px) {
    bottom: 5%;
    left: auto;
    right: auto;
    width: 90%;
    li {
      font-size: 11px;
      width: 50%;
      padding: 0 0 14px 0;
    }
  }
  @media screen and (max-width: 815px) and (max-height: 350px) {
    top: 80vh;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) and (min-height: 351px) {
    top: 300px;
  }
`
const TranslateWord = styled.span`
  transform: translateX(-3%);
  @media only screen and (max-width: 900px) {
    transform: translateX(0);
  }
`

const Bonjour = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          bonjour_animation {
            text {
              text
            }
          }
          top_page {
            text
          }
          top_page1 {
            text
          }
          top_page2 {
            text
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data
  const sizeBonjour = useRef(null)
  const elementContainer = useRef(null)
  const modifyInput = useRef(null)
  const text = useRef(null)
  const [clickable, setclickable] = useState(false)
  const [userInput, setUserInput] = useState(" ")
  const [animText, setanimText] = useState(" ")
  const [showBorder, setshowBorder] = useState(false)
  const [size, setsize] = useState({
    x: 0,
    y: 0,
  })
  const textDesktop = []
  for (let i = 0; i < prismic.bonjour_animation.length; i++) {
    textDesktop.push(prismic.bonjour_animation[i].text.text) // String.fromCodePoint(0x1F60B)
  }
  const handleChange = e => {
    setUserInput(e.target.value)
  }
  const goRightInput = () => {
    modifyInput.current.scrollLeft = modifyInput.current.scrollWidth
    modifyInput.current.setSelectionRange(
      modifyInput.current.value.length,
      modifyInput.current.value.length
    )
  }
  useEffect(() => {
    let mobileDevice = window.innerWidth < 768 ? true : false
    const textIsHere = document.querySelector(".textAnimationClass")
    const emojiText = document.querySelector(".emoji")
    let count = 0
    let index = 0
    let currentText = ""
    let letter = ""
    let animationFinish = false

    const type = () => {
      if (text.current !== null) {
        let textArray = textDesktop
        //check animation state if finished or not
        if (count === textArray.length) {
          animationFinish = true
        }
        if (animationFinish !== true) {
          currentText = textArray[count]
          letter = currentText.slice(0, ++index)

          setanimText(letter)
          text.current.classList.remove("selected")
          if (
            currentText ===
            prismic.bonjour_animation[prismic.bonjour_animation.length - 1].text
              .text
          ) {
            emojiText.style.opacity = 1
            text.current.style.color = "#00000000"
          }
          if (letter.length === currentText.length) {
            setTimeout(() => {
              if (text.current !== null) {
                text.current.classList.add("selected")
              }
            }, 400)
            if (index === 7) {
              setTimeout(() => {
                count++
                index = 0
                setTimeout(type, 130)
              }, 2300)
            } else {
              setTimeout(() => {
                count++
                index = 0
                setTimeout(type, 130)
              }, 1500)
            }
          } else {
            setTimeout(type, 130)
          }
        } else {
          setUserInput("")
          modifyInput.current.value = ""
          text.current.classList.remove("selected")
          emojiText.style.opacity = 0
          text.current.style.color = "#000000"
          setclickable(true)
        }
      } else {
        return
      }
    }
    const resetTextAnim = () => {
      if (animationFinish === false) {
        count = 0
        index = 0
        currentText = ""
        letter = ""
        setclickable(false)
      } else {
        count = 0
        index = 0
        currentText = ""
        letter = ""
        setclickable(false)
        animationFinish = false
        type()
      }
    }
    const checkReset = () => {
      if (elementContainer.current.getBoundingClientRect().y >= 0) {
        resetTextAnim()
      }
    }
    if (textIsHere) {
      setTimeout(type(), 400)
    }
    setsize({
      y: Math.round(sizeBonjour.current.getBoundingClientRect().height),
      x: Math.round(sizeBonjour.current.getBoundingClientRect().width),
    })
    window.addEventListener("scroll", checkReset)
    setTimeout(() => {
      setshowBorder(true)
    }, 0)
    return () => {
      window.removeEventListener("scroll", checkReset)
    }
  }, [])
  return (
    <Container
      ref={elementContainer}
      className="bonjour-section"
      showBorder={showBorder}
    >
      <RandomText>{prismic.top_page.text}</RandomText>
      <Acquisition>{prismic.top_page1.text}</Acquisition>
      <UpsideDown>{prismic.top_page2.text}</UpsideDown>
      <BonjourText
        showBorder={showBorder}
        className={clickable ? "clickable" : "null"}
        ref={sizeBonjour}
      >
        <div className="square"></div>
        <div className="square"></div>
        <div className="square"></div>
        <div className="square"></div>
        <Emoji className="emoji">
          {
            prismic.bonjour_animation[prismic.bonjour_animation.length - 1].text
              .text
          }
        </Emoji>
        <Text ref={text} className="textAnimationClass">
          {clickable ? userInput : animText}
          <TypingIndicator>|</TypingIndicator>
        </Text>
        <InputUser
          ref={modifyInput}
          type="text"
          name="textUser"
          autoComplete="off"
          maxLength="44"
          onClick={goRightInput}
          onChange={handleChange}
        />
      </BonjourText>
      <Sizes showBorder={showBorder}>
        {size.x} x {size.y}
      </Sizes>
      <Summary>
        <li className="click">
          <AnchorLink to="/#mission">
            <span>01.</span> <span>Notre mission</span>
          </AnchorLink>
        </li>
        <li className="click">
          <AnchorLink to="/#chiffres">
            <span>02.</span> <span>Chiffres clés</span>
          </AnchorLink>
        </li>
        <li className="click">
          <AnchorLink to="/#jardin">
            <span>03.</span> <span>Notre jardin</span>
          </AnchorLink>
        </li>
        <li className="click">
          <AnchorLink to="/#client">
            <span>04.</span> <TranslateWord>Espace client dédié</TranslateWord>
          </AnchorLink>
        </li>
        <li className="click">
          <AnchorLink to="/#novlini">
            <span>05.</span> <span>Novlini Score</span>
          </AnchorLink>
        </li>
        <li className="click">
          <AnchorLink to="/#international">
            <span>06.</span> <span>International</span>
          </AnchorLink>
        </li>
      </Summary>
    </Container>
  )
}

export default Bonjour
