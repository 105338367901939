import React, { useContext } from "react"
import styled from "styled-components"
import { size } from "../../elements/font"
import { NumberStringHome, NumberMobile, TitleHome } from "../../elements/Text"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ImgParallax from "../ImgParallax"
import AppContext from "../Context"
import mobile_dia from "../../images/mobile_dia.svg"
import diagramme from "../../images/diagramme.svg"

const ContainerNewGen = styled.div`
  padding: 100px 0 0;
  @media only screen and (max-width: 900px) {
    padding: 0;
  }
`
const IntroText = styled.p`
  max-width: 1440px;
  width: 85%;
  letter-spacing: 0.05em;
  font-size: ${size.XS};
  line-height: calc(${size.XS} * 1.5);
  @media only screen and (max-width: 900px) {
    width: 80vw;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    margin: 0;
    transform: translateX(calc(-50% - 2.5vw));
    left: 50%;
    z-index: 5;
    position: relative;
  }
`
const SchemaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px auto 60px;
  position: relative;
  img {
    width: 70%;
    min-width: 760px;
    max-width: 1100px;
  }
  @media only screen and (max-width: 900px) {
    margin: 30px auto 30px;
    z-index: 200;
    position: relative;
    width: 100%;
    transform: translateX(-1.5vw);
    img {
      min-width: 200px;
      width: 100%;
    }
  }
`
const ImageAndTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`
const Left = styled.div`
  width: 50%;
  height: 400px;
  position: relative;
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 200px;
    margin: 0 0 160px 0;
  }
`
const ImgPamplemousse1 = styled.div`
  position: absolute;
  width: 35%;
  height: auto;
  top: 28%;
  z-index: 0;
  right: 18%;
  @media only screen and (max-width: 900px) {
    left: 4%;
    width: 45%;
    top: 67%;
    right: inherit;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 20%;
  }
`
const TextBlock1 = styled.div`
  position: absolute;
  width: 42%;
  max-width: 350px;
  min-width: 200px;
  top: 5%;
  left: 20%;
  text-align: justify;
  font-size: ${size.XXS};
  @media only screen and (max-width: 900px) {
    width: 90%;
    z-index: 50;
    max-width: 800px;
    position: relative;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    margin: 0;
    transform: translateX(calc(-50% - 1.6vw));
    left: 50%;
  }
`
const Right = styled.div`
  width: 50%;
  height: 400px;
  position: relative;
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 200px;
    margin: 60px 0 0 0;
  }
`
const ImgPamplemousse2 = styled.div`
  position: absolute;
  width: 35%;
  height: auto;
  top: 0%;
  z-index: 1;
  right: 5%;
  @media only screen and (max-width: 900px) {
    bottom: 107%;
    top: inherit;
    width: 47%;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 32%;
  }
`
const TextBlock2 = styled.div`
  position: absolute;
  width: 48%;
  max-width: 350px;
  min-width: 200px;
  top: 52%;
  right: 25%;
  text-align: justify;
  font-size: ${size.XXS};
  z-index: 5;
  @media only screen and (max-width: 900px) {
    width: 90%;
    max-width: 800px;
    position: relative;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    margin: 0;
    transform: translateX(calc(-50% - 1.6vw));
    left: 50%;
  }
`
const NewGen = () => {
  const { isMobile } = useContext(AppContext)
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          body {
            ... on PrismicIndexBodyNewGeneration {
              primary {
                image_left {
                  alt
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 700) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                image_right {
                  alt
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 700) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                left_text {
                  text
                }
                subtitle {
                  text
                }
                right_text {
                  text
                }
                titre_1 {
                  text
                }
                titre_2 {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data
  return (
    <ContainerNewGen>
      {isMobile && (
        <>
          <svg
            className="bulle-mobile-droite"
            style={{
              position: "absolute",
              top: "15%",
              right: "-5%",
              zIndex: "0",
            }}
            width="282"
            height="340"
            viewBox="0 0 282 340"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.805312 182.241C-2.40283 151.087 3.6507 116.136 24.8152 93.065C45.6037 70.4045 83.9188 78.6806 110.298 62.888C137.296 46.7248 148.144 5.98059 179.153 0.66571C209.689 -4.56818 235.66 22.3368 263.119 36.6944C291.759 51.6698 332.648 56.691 344.12 86.9234C355.998 118.228 327.911 150.152 318.346 182.241C311.465 205.325 303.103 226.644 295.643 249.548C286.069 278.94 295.493 322.13 268.116 336.449C240.265 351.017 210.009 316.439 179.153 310.476C155.416 305.889 130.797 313.667 108.036 305.514C82.7263 296.448 59.8003 281.691 42.1499 261.4C22.2772 238.554 3.90803 212.371 0.805312 182.241Z"
              fill="#F5FAFF"
            />
          </svg>
          <svg
            className="bulle-mobile-gauche"
            style={{
              position: "absolute",
              top: "80%",
              left: "-10%",
              zIndex: "0",
            }}
            width="323"
            height="349"
            viewBox="0 0 323 349"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M114.539 0.411088C170.694 5.4221 192.035 70.9256 227.821 114.498C264.823 159.549 335.128 195.417 321.2 252.031C307.301 308.525 230.112 312 174.8 330.011C132.76 343.699 89.4602 358.828 49.1761 340.605C7.82673 321.9 -15.9627 281.076 -29.2926 237.688C-44.3261 188.755 -53.8186 135.508 -27.2839 91.7335C3.3836 41.1417 55.6196 -4.84655 114.539 0.411088Z"
              fill="#F5FAFF"
            />
          </svg>
        </>
      )}
      <NumberStringHome>03.</NumberStringHome>
      <NumberMobile>
        <span>03.</span>
        <span>Notre jardin</span>
      </NumberMobile>
      <TitleHome>
        {prismic.body[2].primary.titre_1.text}{" "}
        <mark>{prismic.body[2].primary.titre_2.text}</mark>
      </TitleHome>
      <IntroText>{prismic.body[2].primary.subtitle.text}</IntroText>
      <SchemaContainer>
        {!isMobile && <img src={diagramme} alt="schéma novlini" />}
        {isMobile && <img src={mobile_dia} alt="schéma novlini" />}
      </SchemaContainer>
      <ImageAndTextContainer>
        <Left>
          <ImgPamplemousse1>
            <ImgParallax speed={4}>
              <Img
                loading="eager"
                fluid={
                  prismic.body[2].primary.image_left.localFile.childImageSharp
                    .fluid
                }
                alt={prismic.body[2].primary.image_left.alt}
              />
            </ImgParallax>
          </ImgPamplemousse1>
          <TextBlock1>{prismic.body[2].primary.left_text.text}</TextBlock1>
        </Left>
        <Right>
          <ImgPamplemousse2>
            <ImgParallax speed={3.6}>
              <Img
                loading="eager"
                fluid={
                  prismic.body[2].primary.image_right.localFile.childImageSharp
                    .fluid
                }
                alt={prismic.body[2].primary.image_right.alt}
              />
            </ImgParallax>
          </ImgPamplemousse2>
          <TextBlock2>{prismic.body[2].primary.right_text.text}</TextBlock2>
        </Right>
      </ImageAndTextContainer>
    </ContainerNewGen>
  )
}

export default NewGen
