import React from "react"
import styled, { keyframes } from "styled-components"
import mapSvg from "../../images/map.svg"

const textclip = keyframes`
    from {
        background-position: 200% center;
    }
    to {
        background-position: 0% center;
    }
`
const ContainerMapSvg = styled.div`
  position: relative;
  > div {
    position: absolute;
    z-index: 1;
    background: #fff;
    border-radius: 50px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    svg {
      width: 40px;
      height: 40px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      z-index: -2;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background: linear-gradient(to right, #a8af64 0%, #fff 50%, #a8af64 100%);
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      animation: ${textclip} 3s ease infinite;
      display: inline-block;
      opacity: 0;
      transition: 0.5s ease opacity;
    }
    &:hover {
      &::after {
        opacity: 0.5;
      }
    }
    &.first {
      left: 14%;
      top: 29%;
    }
    &.second {
      left: 29%;
      top: 66%;
    }
    &.third {
      left: 44%;
      top: 52%;
    }
    &.fourth {
      left: 46%;
      top: 40%;
      span {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50px;
        border: solid 3px #1f4ed2;
      }
    }
    &.fifth {
      top: 76%;
      left: 51%;
    }
    &.sixth {
      top: 29%;
      left: 47%;
    }
    &.seventh {
      left: 50%;
      top: 14%;
    }
    &.eighth {
      top: 26%;
      left: 53%;
    }
    &.nineth {
      top: 47%;
      left: 60%;
    }
    &.tenth {
      top: 18%;
      left: 60%;
    }
    &.eleventh {
      left: 69%;
      top: 43%;
    }
    &.twelve {
      left: 78%;
      top: 38%;
    }
    &.thirteen {
      top: 22%;
      left: 79%;
    }
    &.fourteen {
      left: 81%;
      top: 57%;
    }
    &.fifteen {
      left: 82%;
      top: 31%;
    }
    &.sixteen {
      left: 85%;
      top: 23%;
    }
    &.seventeen {
      left: 87%;
      top: 79%;
    }
  }
  @media only screen and (max-width: 900px) {
    div {
      height: 20px;
      width: 20px;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
`
const MapContainSvg = styled.span`
  width: 100%;
  height: 100%;
  position: relative;
  img {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 900px) {
    svg {
      height: 200px;
    }
  }
`
const Svgmap = () => {
  return (
    <ContainerMapSvg>
      <div className="first">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8643 15.9514L13.1154 14.2426L14.7837 15.7807L17.008 14.7553L18.6762 16.2933L20.9005 13.2173L23.1248 14.7553L24.793 14.2426L25.9052 15.7807L27.5734 14.2426L28.6855 16.2933L27.5734 19.8821L21.4566 22.4454L15.3397 21.4201L12.0033 18.344L11.8643 15.9514Z"
            fill="#99AAB5"
          />
          <path
            d="M26.9914 13.5803C27.003 13.4331 27.0158 13.286 27.0158 13.1363C27.0158 10.0895 24.6114 7.56659 21.4573 7.07698C22.1185 6.94369 22.5867 6.81603 22.0111 6.55046C21.3911 6.26439 20.7711 6.6166 20.3435 6.98419H20.3424C19.9147 6.6166 19.2953 6.26439 18.6747 6.55046C18.0992 6.81603 18.5674 6.94369 19.2285 7.07647C16.075 7.56607 13.67 10.089 13.67 13.1363C13.67 13.286 13.6828 13.4331 13.6945 13.5808C13.4265 16.9644 10.1895 21.9307 20.3429 21.9307C30.4963 21.9307 27.2594 16.9644 26.9914 13.5803Z"
            fill="#FFCC4D"
          />
          <path
            d="M22.0034 13.2167C22.0034 14.0662 21.2566 14.2421 20.3352 14.2421C19.4138 14.2421 18.667 14.0662 18.667 13.2167C18.667 12.3672 19.4138 11.6787 20.3352 11.6787C21.2566 11.6787 22.0034 12.3672 22.0034 13.2167Z"
            fill="#F4900C"
          />
          <path
            d="M16.7286 12.706C17.1893 12.706 17.5628 12.3617 17.5628 11.937C17.5628 11.5123 17.1893 11.168 16.7286 11.168C16.268 11.168 15.8945 11.5123 15.8945 11.937C15.8945 12.3617 16.268 12.706 16.7286 12.706Z"
            fill="#662113"
          />
          <path
            d="M23.962 12.706C24.4227 12.706 24.7962 12.3617 24.7962 11.937C24.7962 11.5123 24.4227 11.168 23.962 11.168C23.5014 11.168 23.1279 11.5123 23.1279 11.937C23.1279 12.3617 23.5014 12.706 23.962 12.706Z"
            fill="#662113"
          />
          <path
            d="M27.5759 18.8579L25.9077 18.3452L23.6834 21.4213L22.0151 18.8579L20.903 19.8832L18.6787 18.8579L17.0105 20.9086L15.3422 17.8325L13.674 18.8579L11.8423 14.877C11.5882 15.5686 11.4497 16.3027 11.4497 17.0635C11.4497 21.1695 15.4334 24.4973 20.3469 24.4973C25.2604 24.4973 29.2441 21.1695 29.2441 17.0635C29.2441 16.4257 29.1379 15.8105 28.9566 15.2194L27.5759 18.8579Z"
            fill="#E1E8ED"
          />
        </svg>
      </div>
      <div className="second">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.8364 22.7896C20.7874 22.7059 20.7102 22.6491 20.6259 22.6077C19.8197 21.8137 20.3921 20.8825 20.3921 20.8825C20.3921 20.6251 20.8969 19.9517 20.3921 19.9517L19.8873 20.4171C19.3825 20.8825 19.3825 22.2786 19.3825 22.2786H18.373C18.0938 22.2786 17.8682 22.4867 17.8682 22.744C17.8682 23.0014 18.0938 23.2094 18.373 23.2094H18.9323L18.8202 23.2653C18.5749 23.3877 18.4835 23.6697 18.6163 23.8959C18.749 24.1221 19.0549 24.2063 19.3008 24.0839L20.6329 23.4198C20.8777 23.2979 20.9691 23.0158 20.8364 22.7896ZM23.9257 22.2786H22.9161C22.9161 22.2786 22.9161 20.8825 22.4113 20.4171L21.9065 19.9517C21.4017 19.9517 21.9065 20.6251 21.9065 20.8825C21.9065 20.8825 22.4795 21.8137 21.6733 22.6077C21.5885 22.6491 21.5118 22.7059 21.4623 22.7896C21.3295 23.0158 21.4209 23.2983 21.6662 23.4203L22.9984 24.0844C23.2437 24.2068 23.5501 24.1225 23.6829 23.8964C23.8156 23.6702 23.7243 23.3877 23.479 23.2657L23.3669 23.2094H23.9257C24.2048 23.2094 24.4305 23.0014 24.4305 22.744C24.4305 22.4867 24.2048 22.2786 23.9257 22.2786Z"
            fill="#FFAC33"
          />
          <path
            d="M29.2271 18.5565C28.7223 17.6257 27.7127 16.2295 27.2079 13.9025H27.2039C27.2044 13.8783 27.2079 13.855 27.2079 13.8308C27.2079 11.065 25.0252 8.77475 22.162 8.3303C22.7622 8.20929 23.1872 8.09341 22.6648 7.85234C22.1019 7.59264 21.5391 7.91237 21.1509 8.24606H21.1499C20.7617 7.91237 20.1994 7.59264 19.636 7.85234C19.1135 8.09341 19.5386 8.20929 20.1388 8.32983C17.2761 8.77429 15.0929 11.0645 15.0929 13.8308C15.0929 13.855 15.0964 13.8783 15.0969 13.9025H15.0929C14.5881 16.2295 13.5785 17.6257 13.0737 18.5565C12.848 18.973 13.0737 19.9527 14.0833 19.4873C14.6845 19.2099 15.2832 18.5974 15.7779 17.7583C16.5578 19.9247 18.1549 21.8142 21.1504 21.8142C24.1458 21.8142 25.743 19.9247 26.5229 17.7583C27.0176 18.5974 27.6163 19.2099 28.2175 19.4873C29.2271 19.9527 29.4527 18.973 29.2271 18.5565Z"
            fill="#FFCC4D"
          />
          <path
            d="M22.666 13.9016C22.666 14.6727 21.988 14.8324 21.1516 14.8324C20.3151 14.8324 19.6372 14.6727 19.6372 13.9016C19.6372 13.1304 20.3151 12.5054 21.1516 12.5054C21.988 12.5054 22.666 13.1304 22.666 13.9016Z"
            fill="#F4900C"
          />
          <path
            d="M17.8773 13.4387C18.2955 13.4387 18.6345 13.1261 18.6345 12.7406C18.6345 12.355 18.2955 12.0425 17.8773 12.0425C17.4591 12.0425 17.1201 12.355 17.1201 12.7406C17.1201 13.1261 17.4591 13.4387 17.8773 13.4387Z"
            fill="#662113"
          />
          <path
            d="M24.4252 13.4387C24.8433 13.4387 25.1824 13.1261 25.1824 12.7406C25.1824 12.355 24.8433 12.0425 24.4252 12.0425C24.007 12.0425 23.668 12.355 23.668 12.7406C23.668 13.1261 24.007 13.4387 24.4252 13.4387Z"
            fill="#662113"
          />
        </svg>
      </div>
      <div className="third">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5444 16.4348L12.7956 14.726L14.4638 16.2641L16.6881 15.2387L18.3564 16.7767L20.5807 13.7007L22.805 15.2387L24.4732 14.726L25.5853 16.2641L27.2536 14.726L28.3657 16.7767L27.2536 20.3655L21.1367 22.9288L15.0199 21.9035L11.6835 18.8274L11.5444 16.4348Z"
            fill="#99AAB5"
          />
          <path
            d="M26.6715 14.0642C26.6832 13.917 26.696 13.7699 26.696 13.6202C26.696 10.5734 24.2915 8.05047 21.1375 7.56087C21.7986 7.42757 22.2669 7.29992 21.6913 7.03435C21.0713 6.74828 20.4513 7.10049 20.0236 7.46807H20.0225C19.5949 7.10049 18.9754 6.74828 18.3549 7.03435C17.7793 7.29992 18.2475 7.42757 18.9087 7.56036C15.7552 8.04996 13.3502 10.5728 13.3502 13.6202C13.3502 13.7699 13.363 13.917 13.3747 14.0647C13.1066 17.4483 9.86971 22.4146 20.0231 22.4146C30.1765 22.4146 26.9396 17.4483 26.6715 14.0642Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.6826 13.7001C21.6826 14.5496 20.9358 14.7255 20.0144 14.7255C19.093 14.7255 18.3462 14.5496 18.3462 13.7001C18.3462 12.8506 19.093 12.1621 20.0144 12.1621C20.9358 12.1621 21.6826 12.8506 21.6826 13.7001Z"
            fill="#F4900C"
          />
          <path
            d="M16.4088 13.1899C16.8695 13.1899 17.2429 12.8456 17.2429 12.4209C17.2429 11.9962 16.8695 11.6519 16.4088 11.6519C15.9482 11.6519 15.5747 11.9962 15.5747 12.4209C15.5747 12.8456 15.9482 13.1899 16.4088 13.1899Z"
            fill="#662113"
          />
          <path
            d="M23.6417 13.1899C24.1024 13.1899 24.4758 12.8456 24.4758 12.4209C24.4758 11.9962 24.1024 11.6519 23.6417 11.6519C23.1811 11.6519 22.8076 11.9962 22.8076 12.4209C22.8076 12.8456 23.1811 13.1899 23.6417 13.1899Z"
            fill="#662113"
          />
          <path
            d="M27.2556 19.3418L25.5874 18.8291L23.3631 21.9051L21.6948 19.3418L20.5827 20.3671L18.3584 19.3418L16.6901 21.3925L15.0219 18.3164L13.3537 19.3418L11.522 15.3608C11.2679 16.0524 11.1294 16.7866 11.1294 17.5474C11.1294 21.6534 15.1131 24.9812 20.0266 24.9812C24.9401 24.9812 28.9238 21.6534 28.9238 17.5474C28.9238 16.9096 28.8176 16.2944 28.6363 15.7033L27.2556 19.3418Z"
            fill="#E1E8ED"
          />
        </svg>
      </div>
      <div className="fourth">
        <span></span>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5971 23.1861C19.5481 23.1024 19.4709 23.0456 19.3866 23.0042C18.5804 22.2102 19.1529 21.2789 19.1529 21.2789C19.1529 21.0216 19.6577 20.3481 19.1529 20.3481L18.6481 20.8135C18.1433 21.2789 18.1433 22.6751 18.1433 22.6751H17.1337C16.8545 22.6751 16.6289 22.8832 16.6289 23.1405C16.6289 23.3979 16.8545 23.6059 17.1337 23.6059H17.693L17.5809 23.6618C17.3356 23.7842 17.2443 24.0662 17.377 24.2924C17.5098 24.5186 17.8157 24.6028 18.0615 24.4804L19.3937 23.8163C19.6385 23.6943 19.7299 23.4123 19.5971 23.1861ZM22.6864 22.6751H21.6768C21.6768 22.6751 21.6768 21.2789 21.1721 20.8135L20.6673 20.3481C20.1625 20.3481 20.6673 21.0216 20.6673 21.2789C20.6673 21.2789 21.2402 22.2102 20.434 23.0042C20.3492 23.0456 20.2725 23.1024 20.223 23.1861C20.0903 23.4123 20.1816 23.6948 20.427 23.8167L21.7591 24.4809C22.0045 24.6033 22.3109 24.519 22.4436 24.2928C22.5764 24.0667 22.485 23.7842 22.2397 23.6622L22.1276 23.6059H22.6864C22.9656 23.6059 23.1912 23.3979 23.1912 23.1405C23.1912 22.8832 22.9656 22.6751 22.6864 22.6751Z"
            fill="#FFAC33"
          />
          <path
            d="M27.9893 18.9525C27.4845 18.0217 26.4749 16.6255 25.9701 14.2985H25.9661C25.9666 14.2743 25.9701 14.251 25.9701 14.2268C25.9701 11.461 23.7874 9.17075 20.9242 8.72629C21.5244 8.60529 21.9494 8.48941 21.427 8.24833C20.8641 7.98864 20.3013 8.30837 19.9131 8.64206H19.9121C19.5239 8.30837 18.9616 7.98864 18.3982 8.24833C17.8758 8.48941 18.3008 8.60529 18.901 8.72583C16.0383 9.17028 13.8551 11.4605 13.8551 14.2268C13.8551 14.251 13.8586 14.2743 13.8591 14.2985H13.8551C13.3503 16.6255 12.3407 18.0217 11.8359 18.9525C11.6102 19.369 11.8359 20.3486 12.8455 19.8833C13.4467 19.6059 14.0454 18.9934 14.5401 18.1543C15.32 20.3207 16.9171 22.2102 19.9126 22.2102C22.908 22.2102 24.5052 20.3207 25.2851 18.1543C25.7798 18.9934 26.3785 19.6059 26.9797 19.8833C27.9893 20.3486 28.2149 19.369 27.9893 18.9525Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.4282 14.2976C21.4282 15.0687 20.7502 15.2284 19.9138 15.2284C19.0774 15.2284 18.3994 15.0687 18.3994 14.2976C18.3994 13.5264 19.0774 12.9014 19.9138 12.9014C20.7502 12.9014 21.4282 13.5264 21.4282 14.2976Z"
            fill="#F4900C"
          />
          <path
            d="M16.6381 13.8347C17.0562 13.8347 17.3952 13.5221 17.3952 13.1366C17.3952 12.751 17.0562 12.4385 16.6381 12.4385C16.2199 12.4385 15.8809 12.751 15.8809 13.1366C15.8809 13.5221 16.2199 13.8347 16.6381 13.8347Z"
            fill="#662113"
          />
          <path
            d="M23.1859 13.8347C23.6041 13.8347 23.9431 13.5221 23.9431 13.1366C23.9431 12.751 23.6041 12.4385 23.1859 12.4385C22.7677 12.4385 22.4287 12.751 22.4287 13.1366C22.4287 13.5221 22.7677 13.8347 23.1859 13.8347Z"
            fill="#662113"
          />
        </svg>
      </div>
      <div className="fifth">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4588 10.8266C13.968 10.3581 13.7407 9.57444 14.5523 8.62425C15.364 7.67405 18.8302 7.38636 18.5804 10.7545C18.549 11.546 18.2211 12.7408 17.3626 14.3673C17.1441 14.7848 16.7694 15.4611 16.6131 16.4544C17.066 16.1089 18.4396 15.49 19.8449 15.6629C21.2498 15.8355 28.2911 18.7434 29.696 17.5198C29.7428 18.0235 27.9945 23.0324 21.0154 23.0324C14.0363 23.0324 12.9126 19.7937 12.9126 18.3259C12.9126 16.8581 13.3187 16.0947 14.4897 15.0729C15.6606 14.0511 17.4566 11.6185 16.6445 10.5389C16.4728 10.8266 15.3491 11.6758 14.4588 10.8266Z"
            fill="#E1E8ED"
          />
          <path
            d="M17.625 19.245C17.3598 20.1663 19.0131 22.087 21.6998 22.0952C25.6226 22.1072 27.726 20.0658 28.897 17.4602C27.7573 16.9996 22.4287 16.0567 20.8835 16.6325C19.3382 17.2083 19.1679 17.3153 17.4692 18.1507C18.0152 18.2374 17.6096 19.245 17.625 19.245Z"
            fill="#CCD6DD"
          />
          <path
            d="M15.4202 10.9763C15.466 10.9396 15.4927 10.8956 15.4927 10.843C15.4927 10.4255 15.524 9.76341 15.6798 9.59088C16.047 9.62668 16.2028 9.27386 15.9689 9.04354C15.735 8.81322 15.3055 9.07244 15.3364 9.317C14.6749 9.15439 14.194 9.25575 14.034 9.71036C13.9853 10.1701 14.1753 10.5544 14.462 10.8283C14.6 10.9599 15.2929 11.0125 15.4202 10.9763Z"
            fill="#292F33"
          />
          <path
            d="M16.4632 9.84619C16.4613 10.2715 16.117 10.7731 15.4134 10.9737C15.339 11.0323 15.2127 11.0698 15.062 11.0893C15.1233 11.1272 15.1874 11.1604 15.2543 11.1885C15.9139 11.2299 16.5193 10.7433 16.6414 10.5385C16.6442 10.5419 16.6452 10.5462 16.6475 10.5497C16.6615 10.1658 16.4632 9.84619 16.4632 9.84619Z"
            fill="#CCD6DD"
          />
          <path
            d="M17.2131 18.1805C17.2285 17.5473 17.8531 14.9995 21.4596 14.9275C25.0661 14.8555 25.3473 16.9426 28.2039 14.7261C28.1571 15.2872 27.1424 21.2032 21.7249 21.2032C17.4161 21.2032 17.1972 18.8137 17.2131 18.1805Z"
            fill="#E1E8ED"
          />
          <path
            d="M13.2044 12.3517C13.6021 12.2379 14.9705 11.5029 15.4702 10.9844C15.6105 10.8404 14.7707 9.94712 13.9899 9.84619C14.0367 10.0765 14.018 10.9555 13.2531 11.7328C13.0501 11.8768 12.7731 12.4238 13.2044 12.3517Z"
            fill="#F4900C"
          />
        </svg>
      </div>
      <div className="sixth">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.555 10.7528C14.0642 10.2844 13.8369 9.50071 14.6485 8.55052C15.4602 7.60032 18.9264 7.31263 18.6766 10.6808C18.6452 11.4723 18.3173 12.667 17.4588 14.2935C17.2403 14.711 16.8656 15.3873 16.7093 16.3807C17.1622 16.0352 18.5357 15.4162 19.9411 15.5892C21.346 15.7617 28.3873 18.6697 29.7922 17.446C29.839 17.9498 28.0907 22.9587 21.1116 22.9587C14.1325 22.9587 13.0088 19.7199 13.0088 18.2522C13.0088 16.7844 13.4149 16.021 14.5858 14.9992C15.7568 13.9774 17.5528 11.5447 16.7407 10.4651C16.569 10.7528 15.4453 11.6021 14.555 10.7528Z"
            fill="#E1E8ED"
          />
          <path
            d="M17.7212 19.1713C17.456 20.0926 19.1093 22.0132 21.796 22.0214C25.7188 22.0335 27.8222 19.9921 28.9931 17.3865C27.8535 16.9258 22.5249 15.983 20.9797 16.5588C19.4344 17.1346 19.2641 17.2415 17.5654 18.077C18.1114 18.1637 17.7058 19.1713 17.7212 19.1713Z"
            fill="#CCD6DD"
          />
          <path
            d="M15.5193 10.9021C15.5651 10.8654 15.5918 10.8214 15.5918 10.7688C15.5918 10.3513 15.6231 9.68919 15.7789 9.51667C16.1462 9.55246 16.302 9.19965 16.068 8.96932C15.8341 8.739 15.4047 8.99822 15.4355 9.24278C14.774 9.08017 14.2931 9.18153 14.1331 9.63614C14.0844 10.0959 14.2744 10.4802 14.5612 10.7541C14.6992 10.8857 15.392 10.9383 15.5193 10.9021Z"
            fill="#292F33"
          />
          <path
            d="M16.5613 9.77197C16.5594 10.1973 16.2151 10.6989 15.5115 10.8994C15.4371 10.9581 15.3108 10.9956 15.1602 11.015C15.2214 11.053 15.2855 11.0862 15.3524 11.1142C16.0121 11.1556 16.6175 10.6691 16.7396 10.4642C16.7424 10.4677 16.7433 10.472 16.7456 10.4755C16.7597 10.0916 16.5613 9.77197 16.5613 9.77197Z"
            fill="#CCD6DD"
          />
          <path
            d="M17.3103 18.1068C17.3257 17.4736 17.9503 14.9258 21.5568 14.8538C25.1633 14.7817 25.4445 16.8689 28.301 14.6523C28.2542 15.2135 27.2395 21.1295 21.822 21.1295C17.5133 21.1295 17.2944 18.74 17.3103 18.1068Z"
            fill="#E1E8ED"
          />
          <path
            d="M13.3001 12.2775C13.6978 12.1636 15.0662 11.4287 15.5659 10.9102C15.7062 10.7662 14.8664 9.8729 14.0856 9.77197C14.1324 10.0023 14.1137 10.8813 13.3488 11.6586C13.1458 11.8026 12.8688 12.3495 13.3001 12.2775Z"
            fill="#F4900C"
          />
        </svg>
      </div>
      <div className="seventh">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4839 16.1897L12.7351 14.4809L14.4033 16.0189L16.6276 14.9936L18.2958 16.5316L20.5201 13.4556L22.7444 14.9936L24.4126 14.4809L25.5248 16.0189L27.193 14.4809L28.3052 16.5316L27.193 20.1203L21.0762 22.6837L14.9594 21.6584L11.6229 18.5823L11.4839 16.1897Z"
            fill="#99AAB5"
          />
          <path
            d="M26.6129 13.819C26.6246 13.6719 26.6374 13.5248 26.6374 13.3751C26.6374 10.3282 24.2329 7.80536 21.0789 7.31575C21.74 7.18246 22.2083 7.0548 21.6327 6.78923C21.0127 6.50316 20.3927 6.85537 19.9651 7.22296H19.9639C19.5363 6.85537 18.9169 6.50316 18.2963 6.78923C17.7207 7.0548 18.1889 7.18246 18.8501 7.31524C15.6966 7.80484 13.2916 10.3277 13.2916 13.3751C13.2916 13.5248 13.3044 13.6719 13.3161 13.8196C13.048 17.2032 9.81112 22.1695 19.9645 22.1695C30.1179 22.1695 26.881 17.2032 26.6129 13.819Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.6245 13.455C21.6245 14.3045 20.8777 14.4804 19.9563 14.4804C19.0349 14.4804 18.2881 14.3045 18.2881 13.455C18.2881 12.6055 19.0349 11.917 19.9563 11.917C20.8777 11.917 21.6245 12.6055 21.6245 13.455Z"
            fill="#F4900C"
          />
          <path
            d="M16.3502 12.9443C16.8109 12.9443 17.1843 12.6 17.1843 12.1753C17.1843 11.7505 16.8109 11.4062 16.3502 11.4062C15.8896 11.4062 15.5161 11.7505 15.5161 12.1753C15.5161 12.6 15.8896 12.9443 16.3502 12.9443Z"
            fill="#662113"
          />
          <path
            d="M23.5812 12.9443C24.0419 12.9443 24.4153 12.6 24.4153 12.1753C24.4153 11.7505 24.0419 11.4062 23.5812 11.4062C23.1205 11.4062 22.7471 11.7505 22.7471 12.1753C22.7471 12.6 23.1205 12.9443 23.5812 12.9443Z"
            fill="#662113"
          />
          <path
            d="M27.1975 19.0962L25.5293 18.5835L23.305 21.6595L21.6367 19.0962L20.5246 20.1215L18.3003 19.0962L16.632 21.1469L14.9638 18.0708L13.2956 19.0962L11.4639 15.1152C11.2098 15.8068 11.0713 16.541 11.0713 17.3018C11.0713 21.4078 15.055 24.7356 19.9685 24.7356C24.882 24.7356 28.8657 21.4078 28.8657 17.3018C28.8657 16.664 28.7595 16.0488 28.5782 15.4577L27.1975 19.0962Z"
            fill="#E1E8ED"
          />
        </svg>
      </div>
      <div className="eighth">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4253 16.4309L12.6765 14.7221L14.3447 16.2602L16.569 15.2348L18.2372 16.7728L20.4615 13.6968L22.6858 15.2348L24.354 14.7221L25.4662 16.2602L27.1344 14.7221L28.2466 16.7728L27.1344 20.3616L21.0176 22.9249L14.9008 21.8996L11.5643 18.8235L11.4253 16.4309Z"
            fill="#99AAB5"
          />
          <path
            d="M26.5524 14.0607C26.5641 13.9136 26.5769 13.7665 26.5769 13.6168C26.5769 10.5699 24.1724 8.04706 21.0183 7.55745C21.6795 7.42416 22.1477 7.2965 21.5722 7.03093C20.9522 6.74486 20.3321 7.09707 19.9045 7.46466H19.9034C19.4758 7.09707 18.8563 6.74486 18.2357 7.03093C17.6602 7.2965 18.1284 7.42416 18.7896 7.55694C15.6361 8.04654 13.231 10.5694 13.231 13.6168C13.231 13.7665 13.2438 13.9136 13.2555 14.0613C12.9875 17.4449 9.75057 22.4112 19.904 22.4112C30.0573 22.4112 26.8204 17.4449 26.5524 14.0607Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.564 13.6967C21.564 14.5462 20.8172 14.7221 19.8958 14.7221C18.9743 14.7221 18.2275 14.5462 18.2275 13.6967C18.2275 12.8472 18.9743 12.1587 19.8958 12.1587C20.8172 12.1587 21.564 12.8472 21.564 13.6967Z"
            fill="#F4900C"
          />
          <path
            d="M16.2897 13.186C16.7503 13.186 17.1238 12.8417 17.1238 12.417C17.1238 11.9922 16.7503 11.6479 16.2897 11.6479C15.829 11.6479 15.4556 11.9922 15.4556 12.417C15.4556 12.8417 15.829 13.186 16.2897 13.186Z"
            fill="#662113"
          />
          <path
            d="M23.5226 13.186C23.9833 13.186 24.3567 12.8417 24.3567 12.417C24.3567 11.9922 23.9833 11.6479 23.5226 11.6479C23.0619 11.6479 22.6885 11.9922 22.6885 12.417C22.6885 12.8417 23.0619 13.186 23.5226 13.186Z"
            fill="#662113"
          />
          <path
            d="M27.1369 19.3379L25.4687 18.8252L23.2444 21.9012L21.5762 19.3379L20.464 20.3632L18.2397 19.3379L16.5715 21.3886L14.9033 18.3125L13.235 19.3379L11.4033 15.3569C11.1492 16.0485 11.0107 16.7827 11.0107 17.5435C11.0107 21.6495 14.9945 24.9773 19.908 24.9773C24.8214 24.9773 28.8052 21.6495 28.8052 17.5435C28.8052 16.9057 28.6989 16.2905 28.5177 15.6994L27.1369 19.3379Z"
            fill="#E1E8ED"
          />
        </svg>
      </div>
      <div className="nineth">
        <svg
          width="41"
          height="39"
          viewBox="0 0 41 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9504 18.2765C15.1135 18.2765 15.2722 18.3316 15.4264 18.4273C16.1027 14.7329 25.4923 14.6682 26.1809 18.2952C27.0045 18.483 27.7035 20.2905 28.0338 22.8341H12.709C13.101 20.1456 13.9557 18.2765 14.9504 18.2765Z"
            fill="#292F33"
          />
          <path
            d="M14.9515 17.2228C16.8908 12.1388 14.687 7.79395 20.1248 7.79395C25.5626 7.79395 24.4434 11.5189 26.6467 16.5105C27.1999 17.7638 25.9334 19.2031 25.9334 19.2031C25.9334 19.2031 24.6422 17.0355 23.5852 16.5387C23.2931 18.0323 22.7201 19.7008 22.7201 19.7008C22.7201 19.7008 21.4442 18.3495 19.9582 17.1545C19.5237 17.6143 18.9374 18.1494 18.1474 18.7323C17.9749 18.2547 17.6813 17.6663 17.3723 17.1025C16.6466 18.1225 15.7993 19.359 15.7993 19.359C15.7993 19.359 14.5599 18.2492 14.9515 17.2228Z"
            fill="#E1E8ED"
          />
          <path
            d="M17.1509 8.70508C14.9955 10.0993 14.4112 9.68953 13.289 10.3285C12.2385 10.9265 12.3003 12.5791 13.2272 14.0029C13.5135 12.7901 12.9801 12.5677 16.657 12.5677C19.1287 12.5677 20.5762 12.6994 20.1174 12.1119C19.6592 11.5244 15.1606 12.3211 17.1509 8.70508Z"
            fill="#FFCC4D"
          />
          <path
            d="M19.6396 9.8522C19.6396 10.2259 19.3118 10.5281 18.9065 10.5281C18.5011 10.5281 18.1729 10.2259 18.1729 9.8522C18.1729 9.78064 18.1882 9.71319 18.2104 9.64893H19.6025C19.6243 9.71319 19.6396 9.78064 19.6396 9.8522Z"
            fill="#292F33"
          />
        </svg>
      </div>
      <div className="tenth">
        <svg
          width="41"
          height="39"
          viewBox="0 0 41 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0473 23.3038C19.9983 23.22 19.9211 23.1633 19.8368 23.1218C19.0306 22.3279 19.6031 21.3966 19.6031 21.3966C19.6031 21.1392 20.1079 20.4658 19.6031 20.4658L19.0983 20.9312C18.5935 21.3966 18.5935 22.7928 18.5935 22.7928H17.5839C17.3047 22.7928 17.0791 23.0008 17.0791 23.2582C17.0791 23.5156 17.3047 23.7236 17.5839 23.7236H18.1432L18.0311 23.7794C17.7858 23.9018 17.6944 24.1839 17.8272 24.4101C17.96 24.6362 18.2659 24.7205 18.5117 24.5981L19.8439 23.934C20.0887 23.812 20.1801 23.53 20.0473 23.3038ZM23.1366 22.7928H22.127C22.127 22.7928 22.127 21.3966 21.6222 20.9312L21.1175 20.4658C20.6127 20.4658 21.1175 21.1392 21.1175 21.3966C21.1175 21.3966 21.6904 22.3279 20.8842 23.1218C20.7994 23.1633 20.7227 23.22 20.6732 23.3038C20.5405 23.53 20.6318 23.8125 20.8772 23.9344L22.2093 24.5985C22.4547 24.7209 22.7611 24.6367 22.8938 24.4105C23.0266 24.1843 22.9352 23.9018 22.6899 23.7799L22.5778 23.7236H23.1366C23.4158 23.7236 23.6414 23.5156 23.6414 23.2582C23.6414 23.0008 23.4158 22.7928 23.1366 22.7928Z"
            fill="#FFAC33"
          />
          <path
            d="M28.4375 19.0701C27.9328 18.1393 26.9232 16.7431 26.4184 14.4162H26.4143C26.4148 14.392 26.4184 14.3687 26.4184 14.3445C26.4184 11.5786 24.2356 9.28842 21.3724 8.84397C21.9726 8.72297 22.3977 8.60708 21.8752 8.36601C21.3124 8.10632 20.7495 8.42604 20.3613 8.75973H20.3603C19.9721 8.42604 19.4098 8.10632 18.8465 8.36601C18.324 8.60708 18.749 8.72297 19.3492 8.8435C16.4865 9.28796 14.3033 11.5782 14.3033 14.3445C14.3033 14.3687 14.3068 14.392 14.3073 14.4162H14.3033C13.7985 16.7431 12.7889 18.1393 12.2841 19.0701C12.0585 19.4867 12.2841 20.4663 13.2937 20.0009C13.8949 19.7235 14.4936 19.1111 14.9883 18.272C15.7682 20.4384 17.3654 22.3279 20.3608 22.3279C23.3563 22.3279 24.9535 20.4384 25.7334 18.272C26.2281 19.1111 26.8267 19.7235 27.428 20.0009C28.4375 20.4663 28.6632 19.4867 28.4375 19.0701Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.8769 14.4147C21.8769 15.1859 21.199 15.3455 20.3625 15.3455C19.5261 15.3455 18.8481 15.1859 18.8481 14.4147C18.8481 13.6436 19.5261 13.0186 20.3625 13.0186C21.199 13.0186 21.8769 13.6436 21.8769 14.4147Z"
            fill="#F4900C"
          />
          <path
            d="M17.0873 13.9523C17.5055 13.9523 17.8445 13.6398 17.8445 13.2542C17.8445 12.8687 17.5055 12.5562 17.0873 12.5562C16.6691 12.5562 16.3301 12.8687 16.3301 13.2542C16.3301 13.6398 16.6691 13.9523 17.0873 13.9523Z"
            fill="#662113"
          />
          <path
            d="M23.6361 13.9523C24.0543 13.9523 24.3933 13.6398 24.3933 13.2542C24.3933 12.8687 24.0543 12.5562 23.6361 12.5562C23.2179 12.5562 22.8789 12.8687 22.8789 13.2542C22.8789 13.6398 23.2179 13.9523 23.6361 13.9523Z"
            fill="#662113"
          />
        </svg>
      </div>
      <div className="twelve">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6943 16.2341L12.9455 14.5254L14.6137 16.0634L16.838 15.038L18.5063 16.5761L20.7306 13.5L22.9549 15.038L24.6231 14.5254L25.7352 16.0634L27.4035 14.5254L28.5156 16.5761L27.4035 20.1648L21.2866 22.7282L15.1698 21.7028L11.8334 18.6268L11.6943 16.2341Z"
            fill="#99AAB5"
          />
          <path
            d="M26.8239 13.863C26.8356 13.7158 26.8483 13.5687 26.8483 13.419C26.8483 10.3722 24.4439 7.8493 21.2898 7.3597C21.951 7.2264 22.4192 7.09875 21.8437 6.83318C21.2236 6.54711 20.6036 6.89931 20.176 7.2669H20.1749C19.7473 6.89931 19.1278 6.54711 18.5072 6.83318C17.9317 7.09875 18.3999 7.2264 19.0611 7.35918C15.9076 7.84879 13.5025 10.3717 13.5025 13.419C13.5025 13.5687 13.5153 13.7158 13.527 13.8635C13.259 17.2472 10.0221 22.2134 20.1754 22.2134C30.3288 22.2134 27.0919 17.2472 26.8239 13.863Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.8355 13.4985C21.8355 14.348 21.0887 14.5238 20.1672 14.5238C19.2458 14.5238 18.499 14.348 18.499 13.4985C18.499 12.649 19.2458 11.9604 20.1672 11.9604C21.0887 11.9604 21.8355 12.649 21.8355 13.4985Z"
            fill="#F4900C"
          />
          <path
            d="M16.5607 12.9887C17.0213 12.9887 17.3948 12.6444 17.3948 12.2197C17.3948 11.795 17.0213 11.4507 16.5607 11.4507C16.1 11.4507 15.7266 11.795 15.7266 12.2197C15.7266 12.6444 16.1 12.9887 16.5607 12.9887Z"
            fill="#662113"
          />
          <path
            d="M23.7921 12.9887C24.2528 12.9887 24.6262 12.6444 24.6262 12.2197C24.6262 11.795 24.2528 11.4507 23.7921 11.4507C23.3315 11.4507 22.958 11.795 22.958 12.2197C22.958 12.6444 23.3315 12.9887 23.7921 12.9887Z"
            fill="#662113"
          />
          <path
            d="M27.406 19.1401L25.7377 18.6274L23.5134 21.7035L21.8452 19.1401L20.7331 20.1655L18.5088 19.1401L16.8405 21.1908L15.1723 18.1148L13.5041 19.1401L11.6724 15.1592C11.4182 15.8508 11.2798 16.5849 11.2798 17.3457C11.2798 21.4518 15.2635 24.7795 20.177 24.7795C25.0905 24.7795 29.0742 21.4518 29.0742 17.3457C29.0742 16.708 28.968 16.0928 28.7867 15.5016L27.406 19.1401Z"
            fill="#E1E8ED"
          />
        </svg>
      </div>
      <div className="thirteen">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8516 16.4309L13.1027 14.7221L14.771 16.2602L16.9953 15.2348L18.6635 16.7728L20.8878 13.6968L23.1121 15.2348L24.7803 14.7221L25.8925 16.2602L27.5607 14.7221L28.6728 16.7728L27.5607 20.3616L21.4439 22.9249L15.327 21.8996L11.9906 18.8235L11.8516 16.4309Z"
            fill="#99AAB5"
          />
          <path
            d="M26.9782 14.0607C26.9898 13.9136 27.0026 13.7665 27.0026 13.6168C27.0026 10.5699 24.5982 8.04706 21.4441 7.55745C22.1053 7.42416 22.5735 7.2965 21.998 7.03093C21.3779 6.74486 20.7579 7.09707 20.3303 7.46466H20.3292C19.9016 7.09707 19.2821 6.74486 18.6615 7.03093C18.086 7.2965 18.5542 7.42416 19.2154 7.55694C16.0619 8.04654 13.6568 10.5694 13.6568 13.6168C13.6568 13.7665 13.6696 13.9136 13.6813 14.0613C13.4133 17.4449 10.1764 22.4112 20.3297 22.4112C30.4831 22.4112 27.2462 17.4449 26.9782 14.0607Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.9898 13.6967C21.9898 14.5462 21.243 14.7221 20.3215 14.7221C19.4001 14.7221 18.6533 14.5462 18.6533 13.6967C18.6533 12.8472 19.4001 12.1587 20.3215 12.1587C21.243 12.1587 21.9898 12.8472 21.9898 13.6967Z"
            fill="#F4900C"
          />
          <path
            d="M16.7159 13.186C17.1766 13.186 17.5501 12.8417 17.5501 12.417C17.5501 11.9922 17.1766 11.6479 16.7159 11.6479C16.2553 11.6479 15.8818 11.9922 15.8818 12.417C15.8818 12.8417 16.2553 13.186 16.7159 13.186Z"
            fill="#662113"
          />
          <path
            d="M23.9489 13.186C24.4095 13.186 24.783 12.8417 24.783 12.417C24.783 11.9922 24.4095 11.6479 23.9489 11.6479C23.4882 11.6479 23.1147 11.9922 23.1147 12.417C23.1147 12.8417 23.4882 13.186 23.9489 13.186Z"
            fill="#662113"
          />
          <path
            d="M27.5627 19.3379L25.8945 18.8252L23.6702 21.9012L22.002 19.3379L20.8898 20.3632L18.6655 19.3379L16.9973 21.3886L15.3291 18.3125L13.6608 19.3379L11.8291 15.3569C11.575 16.0485 11.4365 16.7827 11.4365 17.5435C11.4365 21.6495 15.4202 24.9773 20.3337 24.9773C25.2472 24.9773 29.2309 21.6495 29.2309 17.5435C29.2309 16.9057 29.1247 16.2905 28.9434 15.6994L27.5627 19.3379Z"
            fill="#E1E8ED"
          />
        </svg>
      </div>
      <div className="fourteen">
        <svg
          width="42"
          height="40"
          viewBox="0 0 42 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.6362 16.4748L12.8874 14.7661L14.5556 16.3041L16.7799 15.2787L18.4482 16.8168L20.6725 13.7407L22.8968 15.2787L24.565 14.7661L25.6771 16.3041L27.3454 14.7661L28.4575 16.8168L27.3454 20.4055L21.2285 22.9689L15.1117 21.9435L11.7752 18.8675L11.6362 16.4748Z"
            fill="#99AAB5"
          />
          <path
            d="M26.7633 14.1037C26.775 13.9566 26.7878 13.8094 26.7878 13.6597C26.7878 10.6129 24.3833 8.09002 21.2293 7.60042C21.8904 7.46712 22.3587 7.33947 21.7831 7.0739C21.1631 6.78783 20.5431 7.14004 20.1154 7.50763H20.1143C19.6867 7.14004 19.0672 6.78783 18.4467 7.0739C17.8711 7.33947 18.3393 7.46712 19.0005 7.59991C15.847 8.08951 13.442 10.6124 13.442 13.6597C13.442 13.8094 13.4548 13.9566 13.4665 14.1042C13.1984 17.4879 9.96151 22.4542 20.1149 22.4542C30.2683 22.4542 27.0314 17.4879 26.7633 14.1037Z"
            fill="#FFCC4D"
          />
          <path
            d="M21.774 13.7402C21.774 14.5897 21.0271 14.7655 20.1057 14.7655C19.1843 14.7655 18.4375 14.5897 18.4375 13.7402C18.4375 12.8907 19.1843 12.2021 20.1057 12.2021C21.0271 12.2021 21.774 12.8907 21.774 13.7402Z"
            fill="#F4900C"
          />
          <path
            d="M16.5001 13.2299C16.9608 13.2299 17.3342 12.8856 17.3342 12.4609C17.3342 12.0362 16.9608 11.6919 16.5001 11.6919C16.0395 11.6919 15.666 12.0362 15.666 12.4609C15.666 12.8856 16.0395 13.2299 16.5001 13.2299Z"
            fill="#662113"
          />
          <path
            d="M23.7335 13.2299C24.1942 13.2299 24.5676 12.8856 24.5676 12.4609C24.5676 12.0362 24.1942 11.6919 23.7335 11.6919C23.2729 11.6919 22.8994 12.0362 22.8994 12.4609C22.8994 12.8856 23.2729 13.2299 23.7335 13.2299Z"
            fill="#662113"
          />
          <path
            d="M27.3469 19.3813L25.6787 18.8686L23.4544 21.9447L21.7861 19.3813L20.674 20.4067L18.4497 19.3813L16.7815 21.432L15.1132 18.356L13.445 19.3813L11.6133 15.4004C11.3592 16.092 11.2207 16.8261 11.2207 17.587C11.2207 21.693 15.2044 25.0207 20.1179 25.0207C25.0314 25.0207 29.0151 21.693 29.0151 17.587C29.0151 16.9492 28.9089 16.334 28.7276 15.7429L27.3469 19.3813Z"
            fill="#E1E8ED"
          />
        </svg>
      </div>
      <div className="fifteen">
        <svg
          width="42"
          height="39"
          viewBox="0 0 42 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2799 18.5148C15.4431 18.5148 15.6018 18.5699 15.756 18.6656C16.4323 14.9712 25.8219 14.9065 26.5105 18.5335C27.3341 18.7212 28.0331 20.5288 28.3633 23.0724H13.0386C13.4306 20.3839 14.2853 18.5148 15.2799 18.5148Z"
            fill="#292F33"
          />
          <path
            d="M15.2815 17.4616C17.2209 12.3775 15.0171 8.03271 20.4549 8.03271C25.8927 8.03271 24.7735 11.7577 26.9768 16.7492C27.53 18.0026 26.2635 19.4419 26.2635 19.4419C26.2635 19.4419 24.9722 17.2743 23.9153 16.7775C23.6232 18.271 23.0502 19.9396 23.0502 19.9396C23.0502 19.9396 21.7743 18.5882 20.2883 17.3932C19.8538 17.8531 19.2675 18.3882 18.4775 18.9711C18.305 18.4934 18.0113 17.905 17.7024 17.3413C16.9767 18.3613 16.1294 19.5978 16.1294 19.5978C16.1294 19.5978 14.89 18.488 15.2815 17.4616Z"
            fill="#E1E8ED"
          />
          <path
            d="M17.4809 8.94385C15.3256 10.338 14.7413 9.9283 13.6191 10.5673C12.5686 11.1652 12.6304 12.8179 13.5573 14.2417C13.8435 13.0289 13.3101 12.8065 16.9871 12.8065C19.4588 12.8065 20.9063 12.9382 20.4475 12.3507C19.9893 11.7632 15.4907 12.5599 17.4809 8.94385Z"
            fill="#FFCC4D"
          />
          <path
            d="M19.9697 10.0905C19.9697 10.4642 19.6419 10.7664 19.2365 10.7664C18.8312 10.7664 18.5029 10.4642 18.5029 10.0905C18.5029 10.0189 18.5183 9.95147 18.5405 9.88721H19.9326C19.9543 9.95147 19.9697 10.0189 19.9697 10.0905Z"
            fill="#292F33"
          />
        </svg>
      </div>
      <div className="sixteen">
        <svg
          width="44"
          height="39"
          viewBox="0 0 44 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9702 10.7389C14.4444 10.2578 14.2007 9.45282 15.0704 8.47684C15.9402 7.50087 19.6541 7.20538 19.3864 10.6649C19.3528 11.4779 19.0014 12.705 18.0816 14.3756C17.8475 14.8045 17.446 15.4991 17.2786 16.5194C17.7638 16.1646 19.2355 15.5288 20.7414 15.7065C22.2467 15.8837 29.7913 18.8705 31.2967 17.6137C31.3468 18.1311 29.4735 23.2759 21.9955 23.2759C14.5175 23.2759 13.3135 19.9493 13.3135 18.4417C13.3135 16.9341 13.7486 16.1499 15.0033 15.1004C16.258 14.0509 18.1823 11.5523 17.3121 10.4434C17.1282 10.7389 15.9241 11.6112 14.9702 10.7389Z"
            fill="#E1E8ED"
          />
          <path
            d="M18.3613 19.3862C18.077 20.3325 19.8485 22.3053 22.7274 22.3137C26.9305 22.3261 29.1843 20.2293 30.439 17.553C29.2178 17.0798 23.5083 16.1114 21.8526 16.7028C20.1969 17.2943 20.0145 17.4041 18.1943 18.2623C18.7793 18.3513 18.3447 19.3862 18.3613 19.3862Z"
            fill="#CCD6DD"
          />
          <path
            d="M16.0017 10.893C16.0508 10.8553 16.0794 10.8101 16.0794 10.7561C16.0794 10.3272 16.113 9.64718 16.2799 9.46998C16.6734 9.50675 16.8403 9.14436 16.5897 8.90778C16.3391 8.67121 15.8789 8.93747 15.912 9.18866C15.2032 9.02164 14.6879 9.12575 14.5164 9.59269C14.4643 10.065 14.6678 10.4597 14.9751 10.741C15.123 10.8761 15.8654 10.9302 16.0017 10.893Z"
            fill="#292F33"
          />
          <path
            d="M17.119 9.73242C17.117 10.1692 16.748 10.6845 15.9941 10.8905C15.9144 10.9507 15.7791 10.9893 15.6177 11.0092C15.6833 11.0482 15.752 11.0823 15.8237 11.1111C16.5305 11.1536 17.1791 10.6539 17.31 10.4435C17.313 10.447 17.314 10.4514 17.3165 10.455C17.3315 10.0607 17.119 9.73242 17.119 9.73242Z"
            fill="#CCD6DD"
          />
          <path
            d="M17.9212 18.2928C17.9377 17.6424 18.6069 15.0255 22.4712 14.9515C26.3355 14.8775 26.6368 17.0213 29.6976 14.7446C29.6475 15.321 28.5602 21.3975 22.7554 21.3975C18.1387 21.3975 17.9041 18.9431 17.9212 18.2928Z"
            fill="#E1E8ED"
          />
          <path
            d="M13.6245 12.3059C14.0506 12.189 15.5169 11.4341 16.0522 10.9016C16.2026 10.7536 15.3028 9.83609 14.4662 9.73242C14.5163 9.96899 14.4963 10.8719 13.6767 11.6702C13.4591 11.8182 13.1624 12.3799 13.6245 12.3059Z"
            fill="#F4900C"
          />
        </svg>
      </div>
      <div className="seventeen">
        <svg
          width="44"
          height="40"
          viewBox="0 0 44 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.6599 18.8008C15.8347 18.8008 16.0047 18.8575 16.17 18.9558C16.8946 15.1611 26.9554 15.0947 27.6933 18.82C28.5757 19.0129 29.3247 20.8695 29.6785 23.4821H13.2583C13.6783 20.7206 14.5942 18.8008 15.6599 18.8008Z"
            fill="#292F33"
          />
          <path
            d="M15.661 17.7188C17.739 12.4969 15.3777 8.03418 21.2042 8.03418C27.0307 8.03418 25.8315 11.8602 28.1923 16.9872C28.7851 18.2745 27.428 19.7529 27.428 19.7529C27.428 19.7529 26.0445 17.5264 24.912 17.0162C24.5989 18.5502 23.985 20.2641 23.985 20.2641C23.985 20.2641 22.6179 18.8761 21.0257 17.6486C20.5601 18.121 19.9319 18.6705 19.0855 19.2693C18.9006 18.7787 18.586 18.1743 18.2549 17.5953C17.4773 18.6429 16.5694 19.913 16.5694 19.913C16.5694 19.913 15.2415 18.7731 15.661 17.7188Z"
            fill="#E1E8ED"
          />
          <path
            d="M18.0192 8.96973C15.7098 10.4017 15.0837 9.98089 13.8813 10.6372C12.7558 11.2514 12.822 12.9488 13.8151 14.4113C14.1218 13.1656 13.5503 12.9371 17.4901 12.9371C20.1385 12.9371 21.6894 13.0724 21.1979 12.469C20.7069 11.8656 15.8867 12.6839 18.0192 8.96973Z"
            fill="#FFCC4D"
          />
          <path
            d="M20.6844 10.1478C20.6844 10.5316 20.3332 10.842 19.8988 10.842C19.4645 10.842 19.1128 10.5316 19.1128 10.1478C19.1128 10.0743 19.1292 10.005 19.153 9.93896H20.6446C20.6679 10.005 20.6844 10.0743 20.6844 10.1478Z"
            fill="#292F33"
          />
        </svg>
      </div>
      <MapContainSvg>
        <img src={mapSvg} alt="map novlini" />
      </MapContainSvg>
    </ContainerMapSvg>
  )
}

export default Svgmap
