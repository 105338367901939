import React, { useEffect, useState, useContext } from "react"
import styled, { keyframes } from "styled-components"
import { size, family } from "../../elements/font"
import {
  NumberStringHome,
  NumberMobile,
  TitleHome,
  Alata,
} from "../../elements/Text"
import SliderCard from "./sliderCard"
import { useStaticQuery, graphql } from "gatsby"
import * as ScrollMagic from "scrollmagic"
import AppContext from "../Context"
import { RichText } from "prismic-reactjs"
import { isSafari } from "react-device-detect"

const ContenusContainer = styled.div`
  padding: 40px 0 0;
  @media only screen and (max-width: 900px) {
    margin: 0px 0 40px;
    padding: 0;
  }
`
const FullContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100vh - 20px);
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    padding-top: 50px;
  }
`
const LeftSide = styled.div`
  width: 58%;
  z-index: 5;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`
const RightSide = styled.div`
  width: 28%;
  @media only screen and (max-width: 900px) {
    width: 100%;
    position: relative;
  }
`
const TopText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`
const Paragraph = styled.div`
  font-family: Space Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.XS};
  line-height: calc(${size.XS} * 1.5);
  margin: 10px 0 1.45rem 0;
  letter-spacing: 0.05em;
  max-width: 850px;
  span.r {
    transform: translateY(-13%);
    display: inline-block;
    transition: 0.5s cubic-bezier(0.17, 0.67, 0.16, 1.28) transform;
    transform-origin: center center;
    height: 15px;
  }
  mark {
    background: none;
    font-weight: 500;
    text-decoration: underline;
  }
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    line-height: 18px;
  }
`
const Question = styled.span`
  font-family: Archivo Black;
  font-style: normal;
  font-weight: normal;
  font-size: ${size.S};
  line-height: 27px;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    line-height: 18px;
  }
`
const LoadingBlock = styled.div`
  border: 12px solid #ffe4f9;
  display: block;
  margin: 16% auto;
  border-radius: 55px;
  height: 77vh;
  width: 20%;
  min-width: 220px;
  overflow: hidden;
  max-width: 320px;
  position: relative;
  background: #fff;
  transform: translateZ(0) translateY(6%);
  right: 0%;
  bottom: 0%;
  @media only screen and (max-width: 900px) {
    width: 65%;
    transform: translateZ(0) translateY(0%);
    @media only screen and (max-height: 568px) {
      height: 440px;
    }
    @media only screen and (max-height: 667px) {
      height: 524px;
    }
    @media only screen and (max-height: 812px) {
      height: 567px;
    }
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    height: 300px;
  }
`
const Number = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  color: #88bfe3;
  font-family: ${family.NATS};
  font-style: normal;
  font-weight: normal;
  font-size: 7vw;
  text-align: center;
  letter-spacing: 0.05em;
  z-index: 2;
  @media only screen and (max-width: 900px) {
    font-size: 20vw;
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    font-size: 14vw;
  }
`
const wavingWater = keyframes`
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
`
const FillBar = styled.div`
  background: #88bfe3;
  position: relative;
  bottom: 0;
  transform-origin: bottom center;
  width: 101%;
  height: 100%;
  z-index: 0;
  transform: translateY(100%);
  &::after {
    content: "";
    position: absolute;
    top: -10%;
    left: -25%;
    width: 150%;
    padding-top: 150%;
    background-color: #88bfe3;
    opacity: 1;
    z-index: 0;
    transform-origin: 50% center;
    border-radius: 40%;
    animation: 10s linear 0s infinite normal none running ${wavingWater};
  }
`
const ListInfosBubble = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 20px;
  margin-left: 0;
  height: 42vh;
`
const TitleBubble = styled.li`
  font-family: ${family.Alata};
  color: #1f4ed2;
  font-style: normal;
  font-weight: normal;
  font-size: calc(6px + 0.5vw);
  line-height: calc((6px + 0.5vw) * 1.5);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  letter-spacing: 0.1em;
  transform: translateY(-30%);
  span {
    font-size: calc(10px + 1.2vw);
    padding-bottom: 25px;
  }
`
const ContainerCadre = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin: 0 auto 50px;
  z-index: 1;
`
const CadreBlue = styled.div`
  border: 1.5px solid #1f4ed2;
  border-radius: 3px;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  height: ${props => (props.open ? `${props.height}px` : "60px")};
  transition: height 0.5s ease;
  margin-bottom: 30px;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 1.5px;
    background: #1f4ed2;
    position: absolute;
    left: 0;
    top: 60px;
  }
`

const SeeMore = styled.div`
  height: 35px;
  width: 40px;
  margin: 2px 0;
  border: 1.5px solid #1f4ed2;
  border-radius: 3px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 9px;
  right: 14px;
  svg {
    transition: transform 0.5s ease;
    transform-origin: center;
    transform: ${props => (props.open ? "rotate(-90deg)" : "rotate(0deg)")};
  }
`
const TitleCadreBlue = styled.p`
  font-family: Alata, sans-serif;
  color: #1f4ed2;
  font-size: 12.5px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  width: 100%;
  margin-bottom: 20px;
`
const HiddenText = styled.div`
  font-family: Space Mono, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #000000;
  padding: 19px 24px 36px;
  margin-bottom: 0;
`

const UnderlineAlata = styled(Alata)`
  position: relative;
  display: inline-block;
  font-size: ${size.S};
  transform: translateY(7%);
  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    background: #1f4ed2;
    height: 2px;
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    line-height: 18px;
  }
`
const MovingR = styled.span`
  transform: translateY(-10%);
`

const Contenus = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          body {
            ... on PrismicIndexBodyNovliniScore {
              items {
                title_buble {
                  text
                }
                text_buble {
                  raw
                }
              }
              primary {
                intro {
                  text
                }
                quote {
                  text
                }
                subtitle {
                  raw
                }
                title_0 {
                  text
                }
                title_1 {
                  text
                }
                title_2 {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data
  const { isMobile } = useContext(AppContext)
  const [open0, setopen0] = useState(false)
  const [open1, setopen1] = useState(false)
  const [open2, setopen2] = useState(false)
  const [cadreHeight0, setCadreHeight0] = useState()
  const [cadreHeight1, setCadreHeight1] = useState()
  const [cadreHeight2, setCadreHeight2] = useState()
  useEffect(() => {
    const takeR = document.querySelector(".takeR")
    const insideSpan = takeR.querySelector(
      "span > span + span > span + span + span > span + span"
    )
    insideSpan.innerHTML = '<span class="r">®</span>.'
    const controller = new ScrollMagic.Controller()
    controller.enabled(true)
    const heightWindow = window.innerHeight
    const percentageWater = document.querySelector("#percentageWater")
    const waterRef = document.querySelector("#waterRef")
    const containerFixed = document.querySelectorAll(".scrollmagic-pin-spacer")
    let scene = null
    let pastRule = false
    const $container1 = document.querySelector(".container1-1")
    const $container2 = document.querySelector(".container2-1")
    const $container3 = document.querySelector(".container1-2")
    const $container4 = document.querySelector(".container2-2")
    const $container5 = document.querySelector(".container1-3")
    const $container6 = document.querySelector(".container2-3")
    if (
      $container1 &&
      $container2 &&
      $container3 &&
      $container4 &&
      $container5 &&
      $container6
    ) {
      setCadreHeight0(
        $container1.getBoundingClientRect().height +
          $container2.getBoundingClientRect().height +
          30
      )
      setCadreHeight1(
        $container3.getBoundingClientRect().height +
          $container4.getBoundingClientRect().height +
          30
      )
      setCadreHeight2(
        $container5.getBoundingClientRect().height +
          $container6.getBoundingClientRect().height +
          30
      )
    }
    if (percentageWater !== null && pastRule === false) {
      pastRule = true
      scene = new ScrollMagic.Scene({
        duration: 1000, // the scene should last for a scroll distance of 100px
        offset: (() => {
          if (isMobile && heightWindow <= 500) return 580
          else if (isMobile) return 700
          else if (isMobile && isSafari) return heightWindow + 100
          else if (heightWindow <= 680) return 200
          else if (heightWindow >= 1000) return heightWindow + 100
          else return 300
        })(), // start this scene after scrolling for 50px
        triggerElement: "#triggerWaterAnim",
      })
      scene
        .setPin("#stickBottleAnim")
        .addTo(controller)
        .on("progress", e => {
          percentageWater.innerText = `${Math.round(e.progress * 100)}`
          percentageWater.style.color =
            Math.round(e.progress * 100) < 79 ? "#88BFE3" : "#fff"
          waterRef.style.transform = `translateY(${Math.abs(
            e.progress * 100 - 100
          )}%)`
          if (Math.round(e.progress * 100) === 100) {
            containerFixed.forEach(container => {
              container.style.paddingBottom = "0"
            })
          }
        })
    }
    return () => {
      controller.enabled(false)
    }
  }, [isMobile])
  return (
    <ContenusContainer>
      <div id="triggerWaterAnim"></div>
      <FullContainer id="stickBottleAnim">
        {isMobile ? (
          <svg
            className="bulle-mobile-gauche"
            style={{
              position: "absolute",
              left: "-10%",
              bottom: "0",
              zIndex: "0",
            }}
            width="319"
            height="430"
            viewBox="0 0 319 430"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M317.981 199.519C322.04 238.92 314.381 283.123 287.606 312.3C261.305 340.959 212.832 330.492 179.459 350.465C145.303 370.907 131.578 422.436 92.3486 429.158C53.7165 435.777 20.8592 401.75 -13.8796 383.592C-50.1133 364.653 -101.843 358.303 -116.356 320.068C-131.384 280.476 -95.8502 240.101 -83.7491 199.519C-75.0434 170.324 -64.4652 143.362 -55.0269 114.395C-42.9148 77.2224 -54.8375 22.6 -20.202 4.49066C15.034 -13.9327 53.3117 29.7974 92.3486 37.3386C122.378 43.1399 153.525 33.3031 182.321 43.6145C214.34 55.0805 243.345 73.7432 265.675 99.4055C290.816 128.299 314.056 161.413 317.981 199.519Z"
              fill="#F5FAFF"
            />
          </svg>
        ) : (
          <svg
            className="bulle-home"
            style={{
              transform: "scale(1.2)",
              position: "absolute",
              right: "10%",
              bottom: "0",
              zIndex: "0",
            }}
            width="594"
            height="581"
            viewBox="0 0 594 581"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M301.646 580.563C243.15 574.704 221.823 499.513 174.282 464.947C126.785 430.414 55.2352 429.943 25.1446 379.528C-7.008 325.658 -5.55273 255.921 13.9331 196.293C33.4016 136.719 79.2653 90.3697 131.143 55.1693C181.716 20.8546 240.903 -5.74799 301.646 1.07306C359.447 7.56378 398.256 57.9714 446.414 90.5726C496.8 124.682 571.222 138.228 589.807 196.148C608.392 254.066 560.7 310.441 534.595 365.384C512.129 412.667 487.474 456.94 449.014 492.467C405.924 532.27 360.028 586.411 301.646 580.563Z"
              fill="#F5FAFF"
            />
          </svg>
        )}
        <LeftSide>
          <NumberStringHome>05.</NumberStringHome>
          <NumberMobile>
            <span>05.</span>
            <span>novlini score</span>
          </NumberMobile>
          <TitleHome>
            {prismic.body[4].primary.title_0.text}{" "}
            <mark>{prismic.body[4].primary.title_1.text}</mark>{" "}
            {prismic.body[4].primary.title_2.text}
          </TitleHome>
          <TopText>
            <Paragraph className="takeR">
              {prismic.body[4].primary.subtitle.raw.map((content, i) =>
                content.text.includes("novlini") ? (
                  <span key={i}>
                    {content.text.split(/novlini(?! score)/g).map((item, i) => (
                      <span key={i}>
                        {item.split("\n").map((text, i) => (
                          <span key={i}>
                            {text.includes("novlini score")
                              ? text.split("novlini score").map((item, i) => (
                                  <span key={i}>
                                    {item}
                                    {i === 0 && (
                                      <UnderlineAlata
                                        onMouseEnter={() => {
                                          document.querySelector(
                                            "span.r"
                                          ).style.transform =
                                            "translateY(-13%) rotate(90deg)"
                                        }}
                                        onMouseLeave={() => {
                                          document.querySelector(
                                            "span.r"
                                          ).style.transform =
                                            "translateY(-13%) rotate(0deg)"
                                        }}
                                      >
                                        novlini score
                                      </UnderlineAlata>
                                    )}
                                  </span>
                                ))
                              : text}
                            {i < item.split("\n").length - 1 && !isMobile && (
                              <br />
                            )}
                          </span>
                        ))}
                        {i === 0 && <Alata>novlini</Alata>}
                      </span>
                    ))}
                  </span>
                ) : (
                  <span key={i}>
                    {content.text.split("\n").map((item, i) => (
                      <span key={i}>
                        {item}
                        {!isMobile && <br />}
                      </span>
                    ))}
                  </span>
                )
              )}
            </Paragraph>
            <Paragraph>{prismic.body[4].primary.intro.text}</Paragraph>
            <Question>{prismic.body[4].primary.quote.text}</Question>
          </TopText>
          {!isMobile && (
            <ListInfosBubble>
              <TitleBubble className="bigBubble">
                <span>🐣</span>
                {prismic.body[4].items[0].title_buble.text}
              </TitleBubble>
              <TitleBubble className="bigBubble">
                <span>🤔</span>
                {prismic.body[4].items[1].title_buble.text}
              </TitleBubble>
              <TitleBubble className="bigBubble">
                <span>✌️</span>
                {prismic.body[4].items[2].title_buble.text}
              </TitleBubble>
            </ListInfosBubble>
          )}
        </LeftSide>
        <RightSide>
          <LoadingBlock>
            <Number id="percentageWater">0</Number>
            <FillBar id="waterRef" />
          </LoadingBlock>
        </RightSide>
        {isMobile && (
          <ContainerCadre>
            <CadreBlue open={open0} height={cadreHeight0}>
              <TitleCadreBlue
                onClick={() => setopen0(!open0)}
                className="container1-1"
              >
                {prismic.body[4].items[0].title_buble.text}
              </TitleCadreBlue>
              <SeeMore open={open0} onClick={() => setopen0(!open0)}>
                <svg
                  width="9"
                  height="12"
                  viewBox="0 0 9 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.752264 5.55143L6.4691 1.05518C7.14173 0.524553 8.19812 0.955802 8.19812 1.76111V10.7536C8.19829 10.9338 8.14067 11.1103 8.03216 11.2618C7.92365 11.4134 7.76885 11.5336 7.58629 11.6081C7.40374 11.6826 7.20117 11.7082 7.00284 11.6818C6.80452 11.6555 6.61884 11.5783 6.46805 11.4596L0.753307 6.9633C0.641296 6.87531 0.551522 6.76679 0.490012 6.64503C0.428502 6.52327 0.396677 6.39109 0.396677 6.25736C0.396677 6.12364 0.428502 5.99146 0.490012 5.8697C0.551522 5.74794 0.641296 5.63942 0.753307 5.55143H0.752264Z"
                    fill="#1F4ED2"
                  />
                </svg>
              </SeeMore>
              <HiddenText className="container2-1">
                {RichText.render(prismic.body[4].items[0].text_buble.raw)}
              </HiddenText>
            </CadreBlue>
            <CadreBlue open={open1} height={cadreHeight1}>
              <TitleCadreBlue
                onClick={() => setopen1(!open1)}
                className="container1-2"
              >
                {prismic.body[4].items[1].title_buble.text}
              </TitleCadreBlue>
              <SeeMore open={open1} onClick={() => setopen1(!open1)}>
                <svg
                  width="9"
                  height="12"
                  viewBox="0 0 9 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.752264 5.55143L6.4691 1.05518C7.14173 0.524553 8.19812 0.955802 8.19812 1.76111V10.7536C8.19829 10.9338 8.14067 11.1103 8.03216 11.2618C7.92365 11.4134 7.76885 11.5336 7.58629 11.6081C7.40374 11.6826 7.20117 11.7082 7.00284 11.6818C6.80452 11.6555 6.61884 11.5783 6.46805 11.4596L0.753307 6.9633C0.641296 6.87531 0.551522 6.76679 0.490012 6.64503C0.428502 6.52327 0.396677 6.39109 0.396677 6.25736C0.396677 6.12364 0.428502 5.99146 0.490012 5.8697C0.551522 5.74794 0.641296 5.63942 0.753307 5.55143H0.752264Z"
                    fill="#1F4ED2"
                  />
                </svg>
              </SeeMore>
              <HiddenText className="container2-2">
                {RichText.render(prismic.body[4].items[1].text_buble.raw)}
              </HiddenText>
            </CadreBlue>
            <CadreBlue open={open2} height={cadreHeight2}>
              <TitleCadreBlue
                onClick={() => setopen2(!open2)}
                className="container1-3"
              >
                novlini score {prismic.body[4].items[2].title_buble.text}
              </TitleCadreBlue>
              <SeeMore open={open2} onClick={() => setopen2(!open2)}>
                <svg
                  width="9"
                  height="12"
                  viewBox="0 0 9 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.752264 5.55143L6.4691 1.05518C7.14173 0.524553 8.19812 0.955802 8.19812 1.76111V10.7536C8.19829 10.9338 8.14067 11.1103 8.03216 11.2618C7.92365 11.4134 7.76885 11.5336 7.58629 11.6081C7.40374 11.6826 7.20117 11.7082 7.00284 11.6818C6.80452 11.6555 6.61884 11.5783 6.46805 11.4596L0.753307 6.9633C0.641296 6.87531 0.551522 6.76679 0.490012 6.64503C0.428502 6.52327 0.396677 6.39109 0.396677 6.25736C0.396677 6.12364 0.428502 5.99146 0.490012 5.8697C0.551522 5.74794 0.641296 5.63942 0.753307 5.55143H0.752264Z"
                    fill="#1F4ED2"
                  />
                </svg>
              </SeeMore>
              <HiddenText className="container2-3">
                {RichText.render(prismic.body[4].items[2].text_buble.raw)}
              </HiddenText>
            </CadreBlue>
          </ContainerCadre>
        )}
      </FullContainer>
      <SliderCard />
    </ContenusContainer>
  )
}

export default Contenus
