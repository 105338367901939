import React, { useRef, useContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AppContext from "../Context"
import Img from "gatsby-image"
import styled from "styled-components"
import { size, family } from "../../elements/font"
import Windows98 from "./windows98"
import { NumberStringHome, NumberMobile, TitleHome } from "../../elements/Text"

const WeAreBigContainer = styled.div`
  position: relative;
  margin-top: 60px;
`
const Title = styled(TitleHome)`
  width: 62.2vw;
  @media only screen and (max-width: 900px) {
    width: calc(95% + 0.05em);
  }
`
const BigContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 2vh;
  }
`
const LineSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 7px 10px;
  opacity: ${props => (props.displayed ? 0 : 1)};
  pointer-events: ${props => (props.displayed ? "none" : "all")};
  transition: 0.25s ease opacity;
  span {
    width: 100%;
    font-family: ${family.Archivo};
    font-size: calc(3px + 0.75vw);
    line-height: calc((3px + 0.75vw) * 1.2);
    margin-bottom: 3px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
    opacity: ${props => (props.displayed ? 1 : 1)};
    pointer-events: ${props => (props.displayed ? "all" : "all")};
    span {
      text-align: center;
      line-height: 15px;
      font-size: calc(6px + 0.61vw);
      line-height: calc((6px + 0.61vw) * 1.2);
      width: 100%;
    }
  }
`
const ImgContainer = styled.div`
  position: relative;
  width: 35%;
  margin-bottom: 20px;
  @media only screen and (max-width: 900px) {
    width: 40%;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin: 40px 0 20px 0;
  }
`
const Iphone = styled.div`
  width: 25vw;
  height: auto;
  position: absolute;
  top: 9%;
  left: 100%;
  transform: translateX(-100%);
  background: #000000;
  border-radius: 40px;
  &::after {
    content: "";
    background: #000;
    width: 38%;
    height: 5px;
    border-radius: 100px;
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`
const ContainerIphoneVideo = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: calc(100% - 10px * 2);
  height: calc(100% - 10px * 2);
  margin: 10px;
  overflow: hidden;
  border-radius: 30px;
  transform: translateZ(0);
`
const VideoContainer = styled.video`
  position: relative;
  outline: none;
  width: 100%;
  height: auto;
`
const Rows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &:nth-child(1) {
    margin-bottom: 2vh;
  }
  @media only screen and (max-width: 900px) {
    margin: 0 0 65px 0;
    align-items: flex-end;
    justify-content: space-between;
    width: 95%;
    &:nth-child(1) {
      margin-bottom: 45px;
    }
  }
`
const SmallContainer = styled.div`
  width: 50%;
  position: relative;
  margin: 0;
  background: #fff;
  box-shadow: ${props =>
    props.changeLine
      ? "none"
      : "-5px -5px 40px rgba(0, 0, 0, 0.02), 5px 5px 40px rgba(0, 0, 0, 0.02)"};
  height: 240px;
  transition: 0.3s ease box-shadow, 0.25s ease border;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: ${props => (props.changeLine ? "0.2px solid #000" : "none")};
  &:hover {
    box-shadow: ${props =>
      props.changeLine
        ? "none"
        : "-15px -15px 40px rgba(0, 0, 0, 0.1), 15px 15px 40px rgba(0, 0, 0, 0.1)"};
  }
  &:nth-child(1) {
    margin-right: 1vw;
  }
  @media only screen and (max-width: 900px) {
    margin: 0;
    width: 43%;
    height: auto;
    background: none;
    box-shadow: ${props => (props.changeLine ? "none" : "none")};
    border: ${props => (props.changeLine ? "none" : "none")};
    &:hover {
      box-shadow: ${props => (props.changeLine ? "none" : "none")};
    }
  }
`
const Subtitle = styled.p`
  font-family: Alata, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: calc(5px + 0.5vw);
  line-height: calc((5px + 0.5vw) * 1.4);
  width: 100%;
  margin: 0;
  opacity: ${props => (props.displayed ? 0 : 1)};
  pointer-events: ${props => (props.displayed ? "none" : "all")};
  transition: 0.25s ease opacity;
  @media only screen and (max-width: 900px) {
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    margin-bottom: 10px;
    height: 30px;
    opacity: ${props => (props.displayed ? 1 : 1)};
    pointer-events: ${props => (props.displayed ? "all" : "all")};
  }
`
const Paragraph = styled.div`
  font-size: calc(5px + 0.55vw);
  line-height: calc((5px + 0.55vw) * 1.4);
  position: absolute;
  text-align: left;
  top: 10px;
  left: 15px;
  width: calc(100% - 15px * 2);
  height: calc(100% - 10px * 2);
  margin-top: 5px;
  transition: 0.3s ease opacity;
  opacity: ${props => (props.displayText ? 1 : 0)};
  pointer-events: ${props => (props.displayText ? "all" : "none")};
  p {
    margin-bottom: 1vw;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`
const WeContainer = styled.div`
  padding-top: 180px;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 900px) {
    padding-top: 20px;
  }
`
const CenterText = styled.div`
  width: 100vw;
  position: relative;
  left: -8.5vw;
  margin: 54px 0 70px 0;
  p {
    font-family: Space Mono, sans-serif;
    font-style: normal;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    font-size: ${size.XXS};
    text-align: justify;
    letter-spacing: 0.05em;
    line-height: calc(${size.XXS} * 1.6);
    width: 55%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    span:nth-child(2) {
      margin-top: 5px;
    }
  }
  @media only screen and (max-width: 900px) {
    left: 0;
    margin: 40px 0 170px 0;
    p {
      font-size: 12px;
      line-height: 18px;
      text-align: justify;
      letter-spacing: 0.05em;
      left: -0.5%;
      width: 90%;
      transform: translateX(0);
      span:nth-child(2) {
        display: none;
      }
    }
  }
`
const WeAre = ({ children }) => {
  const { isMobile } = useContext(AppContext)
  const containerWindow = useRef(null)
  const [first, setFirst] = useState(false)
  const [second, setSecond] = useState(false)
  const [third, setThird] = useState(false)
  const [fourth, setFourth] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      prismicIndex {
        data {
          body {
            ... on PrismicIndexBodyPresentationNovlini {
              primary {
                iphone_video {
                  url
                }
                title_1 {
                  text
                }
                title_2 {
                  text
                }
                titre_3 {
                  text
                }
                titre_4 {
                  text
                }
                we_are_text {
                  raw
                }
                windows98_link {
                  url
                }
              }
              items {
                picto {
                  localFile {
                    childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 53) {
                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  alt
                }
                subtitle {
                  text
                }
                text {
                  html
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const prismic = data.prismicIndex.data
  return (
    <WeAreBigContainer ref={containerWindow}>
      <NumberStringHome>01.</NumberStringHome>
      <Title>
        <mark>{prismic.body[0].primary.title_1.text}</mark>{" "}
        {prismic.body[0].primary.title_2.text}{" "}
        <mark>
          {" "}
          <br /> {prismic.body[0].primary.titre_3.text}
        </mark>{" "}
        {prismic.body[0].primary.titre_4.text}
      </Title>
      <BigContainer>
        <Container>
          <Rows>
            <SmallContainer
              onMouseLeave={() => setFirst(false)}
              changeLine={first}
              onClick={() => setFirst(true)}
              className="click"
            >
              <LineSubtitle displayed={first}>
                <ImgContainer>
                  <Img
                    style={{ maxHeight: "60px" }}
                    imgStyle={{ objectFit: "contain" }}
                    loading="eager"
                    alt={prismic.body[0].items[0].picto.alt}
                    fluid={
                      prismic.body[0].items[0].picto.localFile.childImageSharp
                        .fluid
                    }
                  />
                </ImgContainer>
                <span>{prismic.body[0].items[0].title.text}</span>
              </LineSubtitle>
              <Subtitle displayed={first}>
                {prismic.body[0].items[0].subtitle.text}
              </Subtitle>
              <Paragraph
                displayText={first}
                dangerouslySetInnerHTML={{
                  __html: prismic.body[0].items[0].text.html,
                }}
              />
            </SmallContainer>
            <SmallContainer
              onMouseLeave={() => setSecond(false)}
              changeLine={second}
              onClick={() => setSecond(true)}
              className="click"
            >
              <LineSubtitle displayed={second}>
                <ImgContainer>
                  <Img
                    style={{ maxHeight: "60px" }}
                    imgStyle={{ objectFit: "contain" }}
                    loading="eager"
                    alt={prismic.body[0].items[1].picto.alt}
                    fluid={
                      prismic.body[0].items[1].picto.localFile.childImageSharp
                        .fluid
                    }
                  />
                </ImgContainer>
                <span>{prismic.body[0].items[1].title.text}</span>
              </LineSubtitle>
              <Subtitle displayed={second}>
                {prismic.body[0].items[1].subtitle.text}
              </Subtitle>
              <Paragraph
                displayText={second}
                dangerouslySetInnerHTML={{
                  __html: prismic.body[0].items[1].text.html,
                }}
              />
            </SmallContainer>
          </Rows>
          <Rows>
            <SmallContainer
              onMouseLeave={() => setThird(false)}
              changeLine={third}
              onClick={() => setThird(true)}
              className="click"
            >
              <LineSubtitle displayed={third}>
                <ImgContainer>
                  <Img
                    style={{ maxHeight: "60px" }}
                    imgStyle={{ objectFit: "contain" }}
                    loading="eager"
                    alt={prismic.body[0].items[2].picto.alt}
                    fluid={
                      prismic.body[0].items[2].picto.localFile.childImageSharp
                        .fluid
                    }
                  />
                </ImgContainer>
                <span>{prismic.body[0].items[2].title.text}</span>
              </LineSubtitle>
              <Subtitle displayed={third}>
                {prismic.body[0].items[2].subtitle.text}
              </Subtitle>
              <Paragraph
                displayText={third}
                dangerouslySetInnerHTML={{
                  __html: prismic.body[0].items[2].text.html,
                }}
              />
            </SmallContainer>
            <SmallContainer
              onMouseLeave={() => setFourth(false)}
              changeLine={fourth}
              onClick={() => setFourth(true)}
              className="click"
            >
              <LineSubtitle displayed={fourth}>
                <ImgContainer>
                  <Img
                    style={{ maxHeight: "60px" }}
                    imgStyle={{ objectFit: "contain" }}
                    loading="eager"
                    alt={prismic.body[0].items[3].picto.alt}
                    fluid={
                      prismic.body[0].items[3].picto.localFile.childImageSharp
                        .fluid
                    }
                  />
                </ImgContainer>
                <span>{prismic.body[0].items[3].title.text}</span>
              </LineSubtitle>
              <Subtitle displayed={fourth}>
                {prismic.body[0].items[3].subtitle.text}
              </Subtitle>
              <Paragraph
                displayText={fourth}
                dangerouslySetInnerHTML={{
                  __html: prismic.body[0].items[3].text.html,
                }}
              />
            </SmallContainer>
          </Rows>
        </Container>
        {!isMobile ? (
          <Iphone>
            <ContainerIphoneVideo>
              <VideoContainer muted autoPlay loop>
                <source
                  src={prismic.body[0].primary.iphone_video.url}
                  type="video/mp4"
                />
              </VideoContainer>
            </ContainerIphoneVideo>
          </Iphone>
        ) : null}
        <NumberMobile>
          <span>01.</span>
          <span>Présentation</span>
        </NumberMobile>
      </BigContainer>
      <WeContainer className="changeHeaderString">
        {children}
        <CenterText>
          <p>
            {prismic.body[0].primary.we_are_text.raw.map((textPart, index) => (
              <span key={index}>
                {textPart.text}
                {!isMobile && <br />}
              </span>
            ))}
          </p>
        </CenterText>
        <Windows98 url={prismic.body[0].primary.windows98_link.url} />
      </WeContainer>
    </WeAreBigContainer>
  )
}

export default WeAre
