import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const GreenImage = ({ index }) => {
  const data = useStaticQuery(graphql`
    query {
      ecosia: file(relativePath: { eq: "ecosia.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      yuka: file(relativePath: { eq: "yuka.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tgtg: file(relativePath: { eq: "tgtg.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      {index === 0 && (
        <Img
          style={{ margin: 0 }}
          loading="eager"
          fluid={data.ecosia.childImageSharp.fluid}
        />
      )}
      {index === 3 && (
        <Img
          style={{ margin: 0 }}
          loading="eager"
          fluid={data.yuka.childImageSharp.fluid}
        />
      )}
      {index === 4 && (
        <Img
          style={{ margin: 0 }}
          loading="eager"
          fluid={data.tgtg.childImageSharp.fluid}
        />
      )}
    </>
  )
}

export default GreenImage
